import styled, { keyframes } from 'styled-components';

import liku from '../../../assets/image/liku/liku.webp';
import liku2x from '../../../assets/image/liku/liku@2x.webp';
import liku3x from '../../../assets/image/liku/liku@3x.webp';

import link from '../../../assets/image/link/link.webp';
import link2x from '../../../assets/image/link/link@2x.webp';
import link3x from '../../../assets/image/link/link@3x.webp';

import unlink from '../../../assets/image/unlink/unlink.webp';
import unlink2x from '../../../assets/image/unlink/unlink@2x.webp';
import unlink3x from '../../../assets/image/unlink/unlink@3x.webp';

const liku_list = [liku, liku2x, liku3x];
const link_list = [link, link2x, link3x];
const unlink_list = [unlink, unlink2x, unlink3x];

export { liku_list, link_list, unlink_list };

const bounce = keyframes`
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-20px);
	}
`;

export const Title = styled.span`
	font-family: HelveticaNeue-Light;
	font-size: 1.4rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: center;
	color: #201708;

	span {
		font-weight: bold;
	}
`;

export const LinkButton = styled.button`
	position: sticky;
	padding: 0;
	border: 0;
	border-radius: 2.4rem;
	font-family: NotoSansMyanmar-Bold;
	font-size: 1.6rem;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08);
	font-weight: bold;
	font-family: NotoSansMyanmar-Bold;
	color: ${({ theme }) => theme.color.bold};
	background-color: ${({ theme }) => theme.color.background};
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('100%')};
`;

export const LinkInfo = styled.div`
	box-sizing: border-box;
	position: absolute;
	background-color: #fff;
	padding: 1rem 1.3rem 1.6rem;
	z-index: 1;
	border-radius: 1.6rem;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
	animation: ${bounce} 1.25s infinite ease-in-out;
	top: ${({ diff }) => `calc(${-diff.y}px - 60%)`};
	border: 1px solid ${({ theme }) => theme.color.orange};
	${({ theme }) => theme.common.wh('24rem', '6.4rem')};

	p {
		text-align: center;
		font-size: 1.6rem;
		color: ${({ theme }) => theme.color.gray_42};
		line-height: 2rem;
		&.sub {
			font-size: 1.4rem;
		}
		&.main {
			font-weight: bold;
		}
		& .highlight {
			font-weight: bold;
			color: ${({ theme }) => theme.color.orange};
			text-decoration: underline;
		}
	}
	&::before {
		content: '↓';
		position: absolute;
		width: 100%;
		font-size: 4.4rem;
		height: 2.4rem;
		bottom: -1.8rem;
		text-align: center;
		left: 0;
		right: 0;
		color: ${({ theme }) => theme.color.orange};
	}
`;
