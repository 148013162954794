import { get } from '../../utils/Cookie';
import { authApi, createParams } from './index';

export const getContentsList = async (request) => {
	try {
		const { searchTags, ...rest } = request;
		const params = createParams(rest);
		if (searchTags) {
			searchTags.forEach((tag) => {
				if (tag) {
					params.append('searchTags', tag); // 각 태그를 별도로 추가
				}
				if (
					tag === '39' ||
					tag === '41' ||
					tag === '47' ||
					tag === '143' ||
					tag === '5' ||
					tag === '36' ||
					tag === '48'
				) {
					params.append('searchOp', 'title');
				}
			});
		}
		// if (request.params.bookMark) {
		// 	params.append('bookmarked', request.params.bookMark);
		// }
		const url = '/contents/paging2';

		const { data } = await authApi.get(url, { params }); // URL을 사용하여 요청
		const userId = get('id');
		const resultContent = data?.content?.filter((c) => {
			const isValid = c.createMemberId === 1 || c.createMemberId == userId;
			if (!isValid) {
				return false;
			} else {
				return true;
			}
		});
		const result = { ...data, content: resultContent };
		return result;
	} catch (error) {
		console.error(error);
	}
};

export const getRandomPage = async (request) => {
	try {
		const data = await getContentsList(request);
		const maxPage = data?.totalPages;
		const randomPage = Math.floor(Math.random() * maxPage);
		request.page = randomPage;

		const randomData = await getContentsList(request);
		console.log('RandomData', randomData);

		return randomData;
	} catch (error) {
		console.error(error);
	}
};

export const getContentsListAll = async () => {
	try {
		const { data } = await authApi.get('/contents');
		console.log(data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getContents = async (uuid) => {
	try {
		const params = createParams({ uuid });
		const { data } = await authApi.get('/contents/json', { params });
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getBackground = async (uuid) => {
	try {
		const params = createParams({ uuid });
		const { data } = await authApi.get(
			`/contents/background/uuid?uuid=${uuid}`,
		);

		return { ...data };
	} catch (error) {
		return null;
	}
};

export const getContentAttaches = async (contentUuid) => {
	try {
		const params = createParams({ contentUuid });
		const { data } = await authApi.get('/contents/attachment/content-uuid', {
			params,
		});
		console.log('attaches', data);
		return data;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const contentsStart = async (request) => {
	try {
		const { data } = await authApi.post('/contents/start', { ...request });
		return data;
	} catch (error) {
		return null;
	}
};

export const contentsStop = async (request) => {
	try {
		const { data } = await authApi.post('/contents/stop', { ...request });
		return data;
	} catch (error) {
		return null;
	}
};
