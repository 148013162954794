import { makeAutoObservable, reaction } from 'mobx';

import Mqtt from '../../services/mqtt';
import TopicHandlerStrategy from '../../services/mqtt/TopicHandlerStrategy';
import AckHandler from '../../services/mqtt/AckHandler';
import LikuMqtt from '../../services/mqtt/LikuMqtt/LikuMqtt';
import PlayerMqtt from '../../services/mqtt/LikuMqtt/PlayerMqtt';
import LikuHeartBeat from '../../services/mqtt/LikuHeartBeat';

class MqttStore {
	rootStore;
	isConnected = false;
	subscribedTopics = new Set();
	// receivedMessages = [];

	topicHandlerStrategy = TopicHandlerStrategy();
	mqttModule = null;
	ackHandler = null;

	likuMqtt = null;
	playerMqtt = null;
	likuHeartBeat = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;

		// this.connectedHandler = reaction(
		// 	() => this.mqttModule?.connected,
		// 	(connected) => {
		// 		if (connected) this.handleConnect();
		// 		else this.handleDisconnect();
		// 	},
		// );
	}

	setIsConnected(value) {
		this.isConnected = value;
		console.log('this.isConnected', this.isConnected);
	}

	getIsConnected() {
		return this.isConnected;
	}

	handleCustomAlarmMqtt(callback) {
		const callbackResult = callback();
		const { likus, topic, message } = callbackResult;
		console.log('custom callback!!!!', likus, topic, message);
		let callBackCount = 0;
		likus.forEach((liku) => {
			const topic = `liku/${liku}/robot/custom/set`;
			this.subscribeToTopic(topic);
			this.publishMessage(topic, message, (error) => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});
			this.unsubscribeToTopic(topic);
		});
		// setTimeout(() => {
		// 	this.disconnect();
		// }, 1000);
	}

	connect(callback) {
		if (this.mqttModule) {
			console.log('if callback', callback);
			if (
				callback &&
				(callback().type === 'custom' ||
					callback().type === 'add' ||
					callback().type === 'delete')
			) {
				this.handleCustomAlarmMqtt(callback);
			} else {
				this.mqttModule.reconnect();
			}
		} else {
			console.log('else callback', callback);
			this.mqttModule = Mqtt(
				this.topicHandlerStrategy,
				this.setIsConnected.bind(this),
				callback,
			);

			if (
				callback &&
				(callback().type === 'custom' ||
					callback().type === 'add' ||
					callback().type === 'delete')
			) {
				const intervalId = setInterval(() => {
					if (this.isConnected) {
						this.handleCustomAlarmMqtt(callback);
						setTimeout(() => {
							this.disconnect();
						}, 1000);
						clearInterval(intervalId);
					}
				}, 100);
				return;
			}

			this.likuHeartBeat = LikuHeartBeat(
				this.getIsConnected.bind(this),
				this.rootStore.playerControlStore.getIsLoading.bind(
					this.rootStore.playerControlStore,
				),
				this.rootStore.playerControlStore.getIsStart.bind(
					this.rootStore.playerControlStore,
				),
			);
			this.likuMqtt = LikuMqtt(this.getMqttFunction(), this.likuHeartBeat);
			this.playerMqtt = PlayerMqtt(this.getMqttFunction());
			this.ackHandler = AckHandler(this.mqttModule);
		}
	}

	disconnect() {
		for (const topic of this.subscribedTopics) {
			this.unsubscribeToTopic(topic);
		}
		this.mqttModule?.disconnect();
		this.mqttModule = null;
	}

	publishMessage(topic, message, options) {
		this.mqttModule?.publish(topic, message, options);
		this.ackHandler?.generateAckId(topic, message);
	}

	subscribeToTopic(topic, handler) {
		// if (!this.subscribedTopics.has(topic)) {
		if (topic.includes('ack')) {
			handler = {
				handlerFunction: this.ackHandler?.resolveAck.bind(this.ackHandler),
			};
		}

		if (handler) {
			this.addHandler(topic, handler);
		}
		this.mqttModule.subscribe(topic);

		if (!this.subscribedTopics.has(topic)) {
			this.subscribedTopics.add(topic);
		}
	}

	unsubscribeToTopic(topic) {
		if (this.subscribedTopics.has(topic)) {
			this.topicHandlerStrategy.removeHandlerAll(topic);
			this.mqttModule.unsubscribe(topic);
			this.subscribedTopics.delete(topic);
		}
	}

	addHandler(topic, handler) {
		this.topicHandlerStrategy.addHandler(topic, handler);
	}

	removeHandler(topic, handler) {
		this.topicHandlerStrategy.removeHandler(topic, handler);
	}

	getMqttFunction() {
		return {
			publish: this.publishMessage.bind(this),
			subscribe: this.subscribeToTopic.bind(this),
			unsubscribe: this.unsubscribeToTopic.bind(this),
			addHandler: this.addHandler.bind(this),
			removeHandler: this.removeHandler.bind(this),
		};
	}

	// dispose() {
	// 	this.connectedHandler();
	// }
}

export default MqttStore;
