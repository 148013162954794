import { makeAutoObservable } from 'mobx';

class StorageStore {
	rootStore;
	selectStorage = [];

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
	}

	isIncludeStorage(data) {
		return this.selectStorage.find((storage) => storage.url === data.url);
	}

	setSelectStorage(data) {
		const isInclude = this.isIncludeStorage(data);
		if (isInclude) {
			this.selectStorage = this.selectStorage.filter(
				(storage) => storage.name !== data.name,
			);
		} else {
			this.selectStorage.push(data);
		}
	}

	setSelectCategoryItem(list) {
		const isInclude = this.isIncludeStorage(list[0]);
		if (isInclude) {
			this.selectStorage = this.selectStorage.filter(
				(item) => !list.some((item2) => item.url === item2.url),
			);
		} else {
			this.selectStorage = [...this.selectStorage, ...list];
		}
	}

	async downloadFilesFromURLs() {
		const fails = [];
		for (let storage of this.selectStorage) {
			const url = storage?.url;
			try {
				const response = await fetch(url);
				const blob = await response.blob();
				const filename = this.getFilenameFromURL(url);
				this.saveBlobAsFile(blob, filename);
			} catch (error) {
				storage.error = error;
				fails.push(storage);
			}
		}

		if (fails.length > 1) {
			for (let storage of this.selectStorage) {
				alert(`다운로드를 실패했습니다: ${storage.name}`);
				console.error(`다운로드 실패: ${storage.url}`, storage.error);
			}
		}

		alert('다운로드를 완료했습니다.');
		console.log('다운로드 완료');
	}

	getFilenameFromURL(url) {
		const name = decodeURI(url.split('/').pop());
		return name;
	}

	saveBlobAsFile(blob, filename) {
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = filename;
		link.click();
	}
}

export default StorageStore;
