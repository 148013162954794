import styled from 'styled-components';
import remove from '../../../assets/image/remove/remove.svg';
import { theme } from '../../../styles';

export { remove };

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	padding: 1.5rem 3rem;
	white-space: nowrap;
	width: 100%;
	height: 100%;
	max-width: 600px;
	max-height: 80vh;
	background-color: white;
	border-radius: 12px;
`;

export const Header = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
	width: 100%;
	font-size: 2rem;
`;
export const CloseButton = styled.div`
	width: 4rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 4rem;
	font-family: 'NotoSansKR-Regular';
	color: #707070;
	transform: rotate(45deg);
`;

export const SaveButton = styled.button`
	font-size: 1.6rem;
	font-family: 'NotoSansKR-Bold';
	color: ${({ theme }) => theme.color.lightBlue};
	position: absolute;
	top: 0.7rem;
	right: 0;
	background-color: transparent;
	cursor: pointer;
	border: none;
`;

export const DeleteButton = styled.button`
	font-size: 1.6rem;
	font-family: 'NotoSansKR-Bold';
	color: ${({ theme }) => theme.color.red};
	position: absolute;
	top: 0.7rem;
	right: 4rem;
	background-color: transparent;
	cursor: pointer;
	border: none;
`;

export const Content = styled.div`
	width: 100%;
`;

export const AlarmContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;

	.content_flex {
		position: relative;

		.switch_wrapper {
			position: absolute;
			top: 0.8rem;
			right: 1.5rem;
		}
	}

	p.cellTitle {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		background-color: #ebebe9;
		padding: 1rem 2rem;
		font-size: 1.6rem;
		font-family: 'NotoSansKR-Bold';
		color: #707070;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.error {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		background-color: #ebebe9;
		padding: 0rem 2rem;
		font-size: 1.2rem;
		font-family: 'NotoSansKR-Bold';
		color: #b91919;
	}

	.title {
		margin: 1rem 0;
		width: 100%;

		input {
			padding: 0.8rem 1rem;
			font-size: 1.6rem;
			width: 100%;
			border-radius: 12px;
			border: solid 1px #f0f0f0;
		}
	}

	.dayButtons {
		display: flex;
		justify-content: space-between;

		.day {
			padding: 1rem 2rem;
			border-radius: 8px;
			border: 1px solid #ebebeb;
			cursor: pointer;

			&:hover {
				background-color: ${({ theme }) => theme.color.green};
				color: white;
			}

			&.clicked {
				background-color: ${({ theme }) => theme.color.grass};
				color: white;
			}
		}
	}

	.cell {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		border-radius: 1.6rem;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-image: none;
		background-position: right center;
		background-repeat: no-repeat;
		padding-right: 1rem;
	}

	.motion {
		display: flex;
		flex-direction: column;
		row-gap: 0.8rem;
		border-radius: 1.6rem;

		input {
			padding: 0.8rem 1rem;
			font-size: 1.6rem;
			width: 100%;
			border-radius: 12px;
			border: solid 1px #f0f0f0;
		}

		div {
			display: flex;
			justify-content: start;
			column-gap: 1rem;

			div {
				font-size: 1.6rem;
				font-family: 'NotoSansKR-Bold';
				border-radius: 12px;
				border: solid 1px #f0f0f0;
				cursor: pointer;

				&:hover {
					background-color: ${({ theme }) => theme.color.sky};
				}
			}
		}

		.addMotion {
			display: flex;
			justify-content: start;
			align-items: center;
			font-size: 1.6rem;
			font-family: 'NotoSansKR-Bold';
			cursor: pointer;

			span {
				margin-left: 0.4rem;
			}
		}

		select {
			min-width: 100px;
			padding: 0.8rem 1rem;
			width: 100%;
			height: 100%;
			color: white;
			border: none;
			border-radius: 1rem;

			&#actionlist {
				background-color: ${({ theme }) => theme.color.blue};
			}

			&#displaylist {
				background-color: #fe80ab;
			}
		}
	}

	.userSelect > div:hover,
	.userSelect .clicked {
		cursor: pointer;
		background-color: ${({ theme }) => theme.color.gray_eb};
	}

	#user-selections:hover {
		cursor: pointer;
		background-color: rgba(200, 200, 200, 0.4);
		color: transparent;
	}

	#user-selections {
		color: transparent;
		border: none;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #ebebeb;
		border-radius: 1.6rem;
		background-color: transparent;
	}

	.user-selections {
		position: relative;
		width: fit-content;

		.user-text {
			padding: 0.8rem 1rem;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: black;
		}
	}

	.alarm-user {
		display: flex;
		align-items: center;
	}

	.alarm-user:hover {
		background-color: ${({ theme }) => theme.color.gray_eb};
		color: white;
	}

	.timeBox {
		display: flex;
		justify-content: start;
		align-items: center;
		column-gap: 1rem;

		input {
			max-width: 120px;
		}
	}

	.userSelect {
		width: 100%;
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;

		& > div {
			padding: 0.8rem 1rem;
			border-radius: 1.2rem;
			border: 1px solid #ebebeb;
			width: fit-content;

			.cliked {
				cursor: pointer;
				background-color: ${({ theme }) => theme.color.gray_eb};
			}
		}
	}

	.user-selections .switch_wrapper {
		position: relative;
	}

	.switch_input {
		position: absolute;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.switch_label {
		position: relative;
		cursor: pointer;
		display: inline-block;
		width: 48px;
		height: 20px;
		background: #fff;
		border: 2px solid ${({ theme }) => theme.color.blue};
		border-radius: 20px;
		transition: 0.2s;
	}

	.switch_label:hover {
		background: ${({ theme }) => theme.color.sky};
	}

	.onf_btn {
		position: absolute;
		top: 1px;
		left: 1px;
		display: inline-block;
		width: 14px;
		height: 14px;
		border-radius: 20px;
		background: ${({ theme }) => theme.color.blue};
		transition: 0.2s;
	}

	.switch_input:checked + .switch_label {
		background: ${({ theme }) => theme.color.lightBlue};
		border: 2px solid ${({ theme }) => theme.color.lightBlue};
	}

	.switch_input:checked + .switch_label:hover {
		background: ${({ theme }) => theme.color.blue};
	}

	/* move */

	.switch_input:checked + .switch_label .onf_btn {
		left: 28px;
		background: #fff;
		box-shadow: 1px 2px 3px #00000020;
	}
`;
