import { makeAutoObservable } from 'mobx';

export class Liku {
	serial = null;
	uuid = null;
	volume = 0;
	motionStatus = null;
	battery = null;
	version = null;
	language = null;

	store;

	constructor(store, liku) {
		makeAutoObservable(this);
		this.store = store;

		this.serial = liku?.serial || liku;
		this.uuid = liku?.uuid;
		this.volume = liku?.state?.volume;
		this.battery = liku?.state?.battery?.soc;
		this.version = liku?.version;

		// if (isNew) this.updateUserFromServer();
		//
		// 	this.saveHandler = reaction(
		// 		() => this.autoSave,
		// 		(isSave) => {
		// 			if (isSave) {
		// 				this.updateUserFromServer();
		// 				// runInAction(() => {
		// 				this.autoSave = false;
		// 				// });
		// 			}
		// 		},
		// 	);
	}

	update(json) {
		this.serial = json?.serial;
		this.uuid = json?.uuid;
		this.volume = json?.state?.volume;
		this.battery = json?.state?.battery?.soc;
		this.version = json?.version;
	}

	setVolume(data) {
		this.volume = data.volume;
	}

	setMotionStatus(data) {
		this.motionStatus = data.status;
	}

	setLanguage(data) {
		this.language = data.language;
	}
}
