import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import * as S from './Dock.style';

import { RootStoreContext } from '../../App';

import DockLikuConnect from './liku/DockLikuConnect';

import DockLikuMusic from './liku/DockLikuMusic';
import { useClick } from '../../hooks/useClick';
import DockLikuUnConnect from './liku/DockLikuUnConnect';
import DockLikuController from './liku/controller/DockLikuController';
import DockLikuConnecting from './liku/DockLikuConnecting';
import OldPortalModal from '../../pages/main/OldPortalModal';
import { isVisible } from '@testing-library/user-event/dist/utils';

const Dock = observer(() => {
	const { likuStore, mqttStore } = useContext(RootStoreContext);

	const music = useClick(false);
	const controller = useClick(false);

	const musicButtonRef = useRef(null);
	const controllerButtonRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				music.click && // 추가!
				musicButtonRef.current &&
				!musicButtonRef.current.contains(event.target)
			) {
				music.onClick(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [music]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				controllerButtonRef.current &&
				!controllerButtonRef.current.contains(event.target)
			) {
				console.log('[TEST]', controller.click);
				controller.onClick(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [controller]);

	// useEffect(() => {
	// 	const handleClickOutsideMusic = (event) => {
	// 		if (
	// 			music.click &&
	// 			musicButtonRef.current &&
	// 			!musicButtonRef.current.contains(event.target)
	// 		) {
	// 			music.onClick(false);
	// 		}
	// 	};

	// 	document.addEventListener('mousedown', handleClickOutsideMusic);

	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutsideMusic);
	// 	};
	// }, [music]);

	// useEffect(() => {
	// 	const handleClickOutsideController = (event) => {
	// 		if (
	// 			controller.click &&
	// 			controllerButtonRef.current &&
	// 			!controllerButtonRef.current.contains(event.target)
	// 		) {
	// 			controller.onClick(false);
	// 		}
	// 	};

	// 	document.addEventListener('mousedown', handleClickOutsideController);

	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutsideController);
	// 	};
	// }, [controller]);

	useEffect(() => {
		console.log('CHECK-music.click', music.click);
	}, [music.click]);
	useEffect(() => {
		console.log('CHECK-controller.click', controller.click);
	}, [controller.click]);

	return (
		<S.Container>
			<S.Content
				thisLiku={likuStore.thisLiku}
				onClick={() => {
					console.log('docker click');
				}}>
				{likuStore.thisLiku ? (
					mqttStore.likuHeartBeat?.isHeartBeat ? (
						<>
							<S.ControllerWrapper
								ref={controllerButtonRef}
								isVisible={controller.click}>
								<DockLikuController
									controller={controller}
									isVisible={controller.click}
								/>
							</S.ControllerWrapper>

							<S.MusicWrapper ref={musicButtonRef} isVisible={music.click}>
								<DockLikuMusic music={music} isVisible={music.click} />
							</S.MusicWrapper>

							<DockLikuConnect controller={controller} music={music} />
						</>
					) : (
						<DockLikuConnecting />
					)
				) : (
					<DockLikuUnConnect />
				)}

				{/*{likuStore.thisLiku ? (*/}
				{/*	<>*/}
				{/*		<div*/}
				{/*			ref={controllerButtonRef}*/}
				{/*			style={{ padding: '0', margin: '0' }}>*/}
				{/*			<DockLikuController*/}
				{/*				controller={controller}*/}
				{/*				isVisible={controller.click}*/}
				{/*			/>*/}
				{/*		</div>*/}

				{/*		<div ref={musicButtonRef} style={{ padding: '0', margin: '0' }}>*/}
				{/*			<DockLikuMusic music={music} isVisible={music.click} />*/}
				{/*		</div>*/}

				{/*		<DockLikuConnect controller={controller} music={music} />*/}
				{/*	</>*/}
				{/*) : (*/}
				{/*	<DockLikuUnConnect />*/}
				{/*)}*/}
			</S.Content>
			{/*{parseInt(likuStore.thisLiku?.version?.likuVer) < 4 && <OldPortalModal />}*/}
			{/*<OldPortalModal />*/}
		</S.Container>
	);
});

export default Dock;
