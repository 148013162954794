import styled from 'styled-components';
import { Box, Button as DefaultButton } from './DockLikuController.style';

import adjustable from '../../../../assets/image/adjustable/adjustable.webp';
import adjustable2x from '../../../../assets/image/adjustable/adjustable@2x.webp';
import adjustable3x from '../../../../assets/image/adjustable/adjustable@3x.webp';

const adjustable_list = [adjustable, adjustable2x, adjustable3x];

export { adjustable_list };

export const PlayListBox = styled(Box)`
	background-color: #0d152a;
	min-height: 14.8rem;
	max-height: 32rem;
	width: 100%;
	.content {
		min-height: 10.6rem;
		${({ theme }) => theme.common.wh('100%', '27.8rem')};
	}
`;

export const List = styled.div`
	overflow-y: auto;
	gap: 0.8rem;
	margin-bottom: 0.8rem;
	${({ theme }) => theme.common.wh('100%')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
`;

export const Button = styled(DefaultButton)`
	${({ theme }) => theme.common.wh('12.5rem', '3rem')};

	&.list {
		color: #fff;
		background-color: ${({ theme }) => theme.color.gray_20};
	}
	@media (max-width: 1000px) {
		${({ theme }) => theme.common.wh('10rem', '2.4rem')};
	}
`;

export const Motion = styled.div`
	//background-color: #7e84a3;
	min-height: 3.2rem;
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
	${({ theme }) => theme.common.wh('100%', '3.2rem')};
	gap: 10px;
	& > div {
		flex: 1;
	}

	.number {
		aspect-ratio: 1 / 1;
		font-family: NotoSansKR-Regular;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: center;
		color: ${({ theme }) => theme.color.subTitle};
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('auto', '2.4rem')};
	}

	.info {
		//padding: 0.7rem 1.2rem 0.7rem 0rem;
		border-radius: 4px;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
		font-family: NotoSansKR-Regular;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${({ isDone }) => (isDone ? '#000' : '#fff')};
		border: solid 1px ${({ theme }) => theme.color.subTitle};
		background-color: ${({ theme }) => theme.color.gray_35};

		${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
		${({ theme }) => theme.common.wh('20rem', '100%')};

		& div {
			background-color: ${({ isPlay }) => (isPlay ? '#5dffc7' : 'transparent')};
			${({ theme }) => theme.common.wh('0.4rem', '100%')};
		}

		& span {
			padding: 0.7rem 1.2rem 0.7rem 0.8rem;
		}
	}

	& button {
		//padding: 0.02rem 0.06rem;
		//border-radius: 6px;
		//border: solid 1px #202020;
		border: none;
		//font-size: 1.7rem;
		background-color: #343434;
		color: ${({ isDone }) => (isDone ? '#000' : '#fff')};
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('1.5rem', '2.5rem')};
	}

	img {
		${({ theme }) => theme.common.wh('90%')};
	}

	@media (max-width: 1000px) {
		.number {
			font-size: 1.4rem;
		}

		.info {
			//padding: 0.7rem 1.2rem 0.7rem 0rem;
			border-radius: 4px;
			box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
			font-family: NotoSansKR-Regular;
			font-size: 1.4rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: ${({ isDone }) => (isDone ? '#000' : '#fff')};
			border: solid 1px ${({ theme }) => theme.color.subTitle};
			background-color: ${({ theme }) => theme.color.gray_35};

			${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
			${({ theme }) => theme.common.wh('16rem', '100%')};

			& div {
				background-color: ${({ isPlay }) =>
					isPlay ? '#5dffc7' : 'transparent'};
				${({ theme }) => theme.common.wh('0.4rem', '100%')};
			}

			& span {
				padding: 0.7rem 1.2rem 0.7rem 0.8rem;
			}
		}
		& button {
			//padding: 0.02rem 0.06rem;
			//border-radius: 6px;
			//border: solid 1px #202020;
			border: none;
			background-color: #343434;
			color: ${({ isDone }) => (isDone ? '#000' : '#fff')};
			${({ theme }) => theme.common.flex()};
			${({ theme }) => theme.common.wh('1.2rem', '2rem')};
		}
	}
`;
