import styled from 'styled-components';

export const Container = styled.div`
	position: sticky;
	/* position: fixed; */
	//background-color: rgba(13, 21, 42, 0.3);
	z-index: 999;

	bottom: 0;
	//left: 0;
	//right: 0;

	margin: 0 auto; /* 좌우 마진을 자동으로 설정하여 가운데 정렬 */
	padding: 0 0 2rem 0;
	box-sizing: border-box;
	pointer-events: none;
	${({ theme }) => theme.common.flex()};
`;

export const Content = styled.div`
	position: relative;
	margin: 0 auto; /* 좌우 마진을 자동으로 설정하여 가운데 정렬 */
	min-height: 12rem;
	//max-height: 34.4rem;
	//min-width: 65rem;

	pointer-events: none;
	min-width: ${({ thisLiku }) => (thisLiku ? '100%' : '32rem')};
	${({ theme }) => theme.common.flex('column', 'center', 'flex-end')};
`;

export const Box = styled.div`
	position: relative;
	pointer-events: auto;
	//min-width: 65rem;
	background-color: #fff;
	border-radius: 44px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	padding: 2rem 2.4rem;
	${({ theme }) => theme.common.wh('auto', '8.8rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const Left = styled.div`
	flex: 1;
	padding-right: 1.6rem;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-end')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;
export const Center = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;
export const Right = styled.div`
	flex: 1;
	padding-left: 1.6rem;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
	${({ theme }) => theme.common.wh('100%', '100%')};
`;

export const MusicWrapper = styled.div`
	position: absolute;
	bottom: 80%;
	//left: 10%;
	padding: 0;
	margin: 0;
	width: 80%;
	max-width: 162rem;
	display: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
	height: ${({ isVisible }) => (isVisible ? '10vh' : '0')};
	min-height: ${({ isVisible }) => (isVisible ? '60px' : '0')};
`;

export const ControllerWrapper = styled.div`
	position: absolute;
	bottom: 80%;
	//left: 10%;
	padding: 0;
	margin: 0;
	width: 80%;

	max-width: 162rem;
	display: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
	height: ${({ isVisible }) => (isVisible ? '80vh' : '0')};
	min-height: ${({ isVisible }) => (isVisible ? '60px' : '0')};
	@media (max-width: ${({ theme }) => theme.size.desktop}) {
		left: 0%;
		width: 100%;
	}
`;
