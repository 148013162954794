import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import 'react-tooltip/dist/react-tooltip.css';
import * as S from './SearchComponent.style';
import Image from '../../image/Image';
import * as Cookie from '../../../utils/Cookie';
import SearchWordItem from './section/historyWordsSection/searchWordItem/SearchWordItem';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import useClickOutside from '../../../hooks/useClickOutside';

import useEnterKeyEvent from '../../../hooks/useEnterKeyEvent';
import TooltipItem from '../../tooltipItem/TooltipItem';
import useTagListQuery from '../../../hooks/queries/useTagQuery';
import useTagClickHandler from '../../../hooks/useTagClickHandler';
import SearchDetailBox from './SearchDetailBox';

const SearchComponent = observer(() => {
	const location = useLocation();
	// const navigate = useNavigate();
	const searchInputRef = useRef(null);
	const playRegex = /^\/play($|\/)/;
	const { data: tags } = useTagListQuery();
	const [searchWordList, setSearchWordList] = useState([]);
	const [currentSearchWord, setCurrentSearchWord] = useState(null);
	const [isCookieHere, setIsCookieHere] = useState(false);
	const [profileName, setProfileName] = useState(null);
	const [wordHistoryCookie, SetWordHistoryCookie] = useCookies([
		'searchWordListHistory',
	]);
	const tagClickHandler = useTagClickHandler();

	const [suggestedTags, setSuggestedTags] = useState([]);
	const [blurTimeoutId, setBlurTimeoutId] = useState(null);

	const [isSearchInputFocus, setSearchInputFocus] = useState(false);
	const [isSearchBarClicked, setIsSearchBarClicked] = useState(false);
	const searchBarRef = useRef(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (searchInputRef.current?.value) {
			const similarTags = tags?.filter((tag) =>
				tag.name.includes(searchInputRef.current.value),
			);
			console.log('similarTags', similarTags);
			setSuggestedTags(similarTags);
		} else {
			setSuggestedTags([]);
		}
	}, [searchInputRef.current?.value, tags]);

	// 사용자 로그인 이벤트 처리
	const handleUserLogin = () => {
		// 사용자가 로그인하면 쿠키에서 검색어 목록 가져오기
		try {
			const searchWordHistoryCookie = Cookie.get('searchWordListHistory');
			const pfName = Cookie.get('profile').name;
			const pfWordHistory = searchWordHistoryCookie[pfName];

			if (pfWordHistory && pfWordHistory.length > 0) {
				setSearchWordList(pfWordHistory);
			}
		} catch (error) {
			console.log('쿠키 정보 불러오기 실패 : ' + error.message);
		}
	};

	// 프로필 이름 저장
	useEffect(() => {
		const pfCookie = Cookie.get('profile');
		const pfName = pfCookie.name;

		setProfileName(pfName);
	}, []);

	// 페이지 로드 시 쿠키에서 검색어 목록 가져오기
	useEffect(() => {
		try {
			const profileCookie = Cookie.get('profile');
			const collectionNameCookie = Cookie.get('collectionName');

			if (profileCookie && collectionNameCookie) {
				setIsCookieHere(true);
				handleUserLogin();
			}
		} catch (error) {
			console.log('쿠키 정보 불러오기 실패 : ', error.message);
		} finally {
			// console.log('쿠키 정보 불러오기 작업 끝');
		}
	}, []);

	// 검색어 입력 시 주소창 업데이트
	useEffect(() => {
		updateSearchTitle();
	}, [searchWordList, currentSearchWord]);

	// 검색어 목록 변경 시 쿠키에 반영
	useEffect(() => {
		updateSearchWordListFromCookie();
	}, [searchWordList]);

	// 검색어 추가
	const search = () => {
		setSearchWordList((state) => {
			setIsSearchBarClicked(false);
			let newSearchWord = searchInputRef.current.value.trim(); // 공백 제거
			if (newSearchWord?.length) {
				const uniqueSearchWordsSet = new Set([...state, newSearchWord]);
				const uniqueSearchWordsAry = Array.from(uniqueSearchWordsSet);

				setCurrentSearchWord(newSearchWord);
				updateSearchTitle();

				return uniqueSearchWordsAry;
			} else {
				return '';
			}
		});
	};

	// 검색어 삭제
	const removeSearchInput = (_index) => {
		if (!searchInputRef.current) return;
		if (_index === undefined || _index === null) {
			searchInputRef.current.value = '';
		} else {
			const tempList = [...searchWordList];
			tempList.splice(_index, 1);
			setSearchWordList(tempList);
			setCurrentSearchWord(null);
			updateSearchTitle();
		}
	};

	// 검색어 목록 업데이트
	const updateSearchWordListFromCookie = () => {
		if (isCookieHere && searchWordList) {
			const existingSearchWordListHistory =
				wordHistoryCookie.searchWordListHistory || {};

			const newSearchWordListHistory = {
				...existingSearchWordListHistory,
				[profileName]: searchWordList,
			};

			SetWordHistoryCookie(newSearchWordListHistory);

			Cookie.set(
				'searchWordListHistory',
				newSearchWordListHistory,
				'{expires: 7 }',
			);
		}
	};

	// 주소창 업데이트
	const updateSearchTitle = () => {
		const searchParams = new URLSearchParams(location.search);
		const currentPath = location.pathname;
		searchParams.delete('searchTitle');

		if (!(searchWordList?.length && currentSearchWord)) return;

		searchParams.append('searchTitle', currentSearchWord);
		navigate(`${currentPath}?${searchParams.toString()}`);
	};

	// 검색어 목록 아이템 클릭 핸들러
	const handleWordHistoryItemClick = (itemIndex) => {
		const word = searchWordList[itemIndex];

		searchInputRef.current.value = word;
		setCurrentSearchWord(word);
		updateSearchTitle();
	};

	// 검색 기록을 모두 지우기
	const deleteAllHistoryWords = () => {
		let answer = window.confirm('검색 기록을 모두 지울까요?');

		if (answer) {
			if (searchWordList.length > 0) {
				setSearchWordList([]);
			}
		}
	};

	// 검색어 입력창에서 엔터키 입력 감지
	useEnterKeyEvent({
		elementRef: searchInputRef,
		functionToExecute: search,
	});

	useEffect(() => {
		console.log('[isSearchBarClicked]', isSearchBarClicked);
	}, [isSearchBarClicked]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				searchBarRef.current &&
				!searchBarRef.current.contains(event.target)
			) {
				setIsSearchBarClicked(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchBarRef]);

	if (playRegex.test(location.pathname) || location.pathname === '/main') {
		return (
			<S.Container ref={searchBarRef}>
				{/* 1. 검색창 */}
				<S.SeachInputContainer>
					<S.SearchBarWrapper>
						<S.SearchInput
							placeholder='검색어를 입력하세요'
							ref={searchInputRef}
							onFocus={() => {
								clearTimeout(blurTimeoutId);
								setSearchInputFocus(true);
							}}
							onBlur={() => {
								const timeoutId = setTimeout(
									() => setSearchInputFocus(false),
									200,
								);
								setBlurTimeoutId(timeoutId);
							}}
							onClick={() => setIsSearchBarClicked(true)}
						/>
						<S.SearchButton
							onClick={search}
							onMouseEnter={(e) => e.target.classList.add('hovered')}
							onMouseLeave={(e) => e.target.classList.remove('hovered')}>
							<Image srcArray={S.search_list} />
						</S.SearchButton>
					</S.SearchBarWrapper>
				</S.SeachInputContainer>

				{/* 2. 검색 상세 정보 창 */}
				<S.SearchDetailBoxWrapper>
					<SearchDetailBox
						isDetailPanelOn={isSearchBarClicked}
						searchWordList={searchWordList}
						handleWordHistoryItemClick={handleWordHistoryItemClick}
						removeSearchInput={removeSearchInput}
						deleteAllHistoryWords={deleteAllHistoryWords}
					/>
					<S.BlackBackground
						isDetailPanelOn={isSearchBarClicked}
						onMouseDown={() => setIsSearchBarClicked(false)}
					/>
				</S.SearchDetailBoxWrapper>
			</S.Container>
		);
	} else if (location.pathname === '/') {
		// return <S.PortalWord>PORTAL</S.PortalWord>;
		return <></>;
	}
});

export default SearchComponent;
