import React, { useEffect, useRef, useState } from 'react';
import * as S from './timeInput.style';
import useClickOutside from '../../hooks/useClickOutside';

const UserSelect = ({
	placeholder = '사용자 선택',
	id,
	setFaceUserIds,
	users,
}) => {
	const [value, setValue] = useState(placeholder);
	const [hourFocus, setHourFocus] = useState(false);
	const hourRef = useRef(null);

	useClickOutside({
		elementRef: hourRef,
		stateVariable: hourFocus,
		stateFunction: setHourFocus,
	});

	// console.log('value', hour);

	return (
		<S.Container>
			<S.TimeBox>
				<S.Viewer
					ref={hourRef}
					onClick={(e) => {
						setHourFocus((state) => !state);
					}}>
					{value}
				</S.Viewer>
				<S.Ul open={hourFocus}>
					{[...users].map((user) => (
						<li
							key={user.id + '-' + user.name}
							onClick={() => {
								setFaceUserIds((state) => {
									const userId = parseInt(user.id);
									return state.includes(userId) ? state : [...state, userId];
								});
							}}>
							{user.name}
						</li>
					))}
				</S.Ul>
			</S.TimeBox>
		</S.Container>
	);
};

export default UserSelect;
