import axios from 'axios';
import { authApi } from './index';

export const postGreetJson = async (json) => {
	let jsonFile = new Blob([json], { type: 'application/json' });
	const { data } = await authApi.get('/portal/collection/member');
	const userUuid = data.collection.uuid;
	const userName = data.collection.name;
	let formData = new FormData();
	formData.append('file', jsonFile, 'greet.json');
	formData.append('name', userName);
	formData.append('uuid', userUuid);
	// try {
	// 	const deleteResult = await authApi.delete(
	// 		`contents/greeting/collection/${userUuid}`,
	// 	);
	// 	console.log(deleteResult);
	// } catch (error) {
	// 	console.error(error);
	// }
	try {
		const result = await authApi.post('contents/greeting', formData);
		return result;
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const getAlarmList = async () => {
	try {
		// const params = createParams({ uuid });
		const { data } = await authApi.get('/portal/alarm');
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const makeAlarm = async (alarm) => {
	try {
		// const params = createParams({ uuid });
		const { data } = await authApi.post('/portal/alarm', { ...alarm });
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const updateAlarm = async (alarm) => {
	try {
		const { data } = await authApi.put(`/portal/alarm/${alarm.id}`, {
			...alarm,
		});
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteAlarm = async (alarm) => {
	try {
		const { data } = await authApi.delete(`/portal/alarm/${alarm.id}`);
		return data;
	} catch (error) {
		console.error(error);
	}
};

//
// export const getAlarmList = async (collectionName) => {
// 	// const { data } = await authApi.get(`contents/alarm/collection/${collectionName}`);
// 	const data = [
// 		{
// 			id: 1,
// 			name: '환영인사',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'안녕하세요. 리쿠 정책 설명회에 오신 걸 환영합니다!',
// 				'만나서 반가워요. 리쿠 정책 설명회에 함께 해주셔서 감사합니다!',
// 				'참석해 주셔서 감사합니다. 리쿠 정책 설명회에 함께하게 되어 기뻐요!',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['9:00', '10:20']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 2,
// 			name: '정책 설명회 시작',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'리쿠 정책 설명회는 10시 30분에 시작됩니다. 시간에 맞춰, 자리에 착석 부탁드려요!',
// 				'시간에 맞춰 컨퍼런스홀로 들어오셔서 자리에 착석해 주시면 감사하겠습니다.',
// 				'오전 10시 30분부터 리쿠 지사 정책 설명회가 시작됩니다!',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['10:20', '10:29']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 3,
// 			name: '정책 설명회 시작2',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: ['오전 10시 30분입니다. 리쿠 지사 정책 설명회를 시작하겠습니다!'],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['10:30', '10:30']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 4,
// 			name: '점심시간',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'컨퍼런스홀을 나가서 오른쪽으로 가시면 식당이 있어요! 맛있는 식사 되세요!',
// 				'점심 식사 후 오후 1시 10분부터 2부가 시작됩니다. 맛있는 점심 드세요!',
// 				'점심 메뉴가 뷔페라고 해요! 맛있는 식사하시고, 2부에서 만나요!',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['12:00', '12:09']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 5,
// 			name: '점심시간2',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: ['12시 10분입니다. 점심 식사 시간이에요! 식사 맛있게 하세요!'],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['12:10', '12:10']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 6,
// 			name: '리쿠 교육 설명',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'맛있게 드셨나요? 1시 10분부터 2부가 시작됩니다. 시작전에 차 한잔 어떠세요?',
// 				'식사는 맛있으셨나요? 1시 10분에, 2부에서 리쿠 교육 설명이 시작될 예정입니다.',
// 				'점심 맛있게 드셨나요? 2부에는 리쿠 교육 설명이 진행됩니다.',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['12:11', '13:09']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 7,
// 			name: '리쿠 교육 설명2',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: ['오후 1시 10분입니다. 리쿠 지사 정책 설명회 2부를 시작하겠습니다!'],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['13:10', '13:10']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 8,
// 			name: '리쿠 교육 설명3',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'리쿠에 대해 많이 알게 되셨나요? 더 많은 이야기를 나누어 봐요!',
// 				'리쿠 정책 설명회가 종료되었습니다. 유익한 시간이었기를 바래요!',
// 				'리쿠 교육 컨텐츠에 대해 많이 알게 되셨나요? 다양하게 활용해 보세요!',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['14:30', '14:50']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
// 		{
// 			id: 9,
// 			name: '행사 종료',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'오늘 참석해주셔서 감사합니다. 다음에 있을, 리쿠 지사 정책 설명회에서 만나요!',
// 				'오늘 만나서 반가웠어요. 우리 자주 만나요! 다음은 유럽에서 만날까요?',
// 				'오늘 즐거웠어요! 다음에 있을, 리쿠 지사 정책 설명회에서 만나요!',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [['14:50', '16:00']],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'Anyone',
// 			face_user_ids: [],
// 		},
//
// 		{
// 			id: 10,
// 			name: '환영인사2',
// 			action: 'general3',
// 			display: 'joy',
// 			TTS: [
// 				'오늘 날씨가 너무 좋아요! 기분이 좋은걸요?',
// 				'몇시에 도착하셨나요? 저는 엄청 일찍왔어요!',
// 				'오시는데 힘드시진 않았나요?',
// 				'오실 때 무엇을 타고 오셨나요? 막히진 않았나요?',
// 				'아침식사 하셨나요? 아침 식사가 건강에 좋다더라구요!',
// 				'오늘 끝나고 계획이 있으신가요?',
// 				'누구와 함께 오셨나요? 혼자 오셨나요?',
// 				'주말에 즐거운 계획이 있으신가요? 대전에서 보내는 건 어떠세요?',
// 				'대전에 처음 오셨나요? 저는 벌써 세번째에요!',
// 				'리쿠 어디가 매력적인가요? 저한테만 말씀해 보세요!',
// 				'오늘 멋진옷을 입으셨네요! 멋쟁이세요!',
// 				'리쿠 왜 좋아하세요? 제가 쫌 귀엽죠?!',
// 				'이렇게 똑똑한 로봇 본 적 있으세요? 저에게 질문해 보세요!',
// 				'날씨가 더워진거 같아요! 시원한 차 한잔 어떠세요?',
// 				'어디서 오셨어요? 저는 서울에서 왔어요!',
// 				'머리스타일이 멋지신데요? 입고계신 옷과 아주 잘어울려요!',
// 				'오늘 즐거운 일이 생길 것 같지 않나요?',
// 				'커피 좋아하세요? 차 한잔 어떠세요?',
// 				'예쁜옷을 입으셨네요! 누가 고르셨나요? 안목이 대단하세요!',
// 				'오늘 점심식사가 뭔지 아세요? 마춰보세요!',
// 			],
// 			start_date: '2024-06-20 00:00',
// 			end_date: '2024-06-20 23:59',
// 			state: 'ON',
// 			time: [],
// 			frequency: '',
// 			repeat: 'xxxoxxx',
// 			check: 'NO',
// 			condition: 'No one',
// 			face_user_ids: [],
// 		},
// 	];
// 	const data2 = await axios.get('http://13.209.56.136:8080/alarm');
// 	return data2;
// };

export const switchAlarm = async (alarmId, state) => {
	const { data } = await authApi.put(`/portal/alarm/${alarmId}`, {
		id: alarmId,
		state: state,
	});
	return data;
};

// export const makeAlarm = async (alarm) => {
// 	const { data } = await axios.post('http://13.209.56.136:8080/alarm/make', {
// 		...alarm,
// 	});
// };
