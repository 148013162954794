import React, { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';

import * as S from './DockLikuMusic.style';

import Image from '../../image/Image';

import { useClick } from '../../../hooks/useClick';
import { GROUPS, MUSICS } from '../../../data/music';

import { RootStoreContext } from '../../../App';

const DockLikuMusic = observer(({ music, isVisible }) => {
	const { likuStore, authStore } = React.useContext(RootStoreContext);
	const isSenior = authStore.auth?.role?.some((role) => role.name === 'senior');

	const { click: isGroup, onClick: setIsGroup } = useClick(false);
	const { click: isMusicList, onClick: setIsMusicList } = useClick(false);

	const localStorageUsername = localStorage.getItem('isShuffle') === 'true';
	const { click: isShuffle, onClick: setIsShuffle } = useClick(
		localStorageUsername || false,
	);

	const [isAnimating, setIsAnimating] = useState(true);
	const [isPlay, setisPlay] = useState(false);

	const [choseGroup, setChoseGroup] = useState(null);
	const [choseMusic, setChoseMusic] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState();

	const [filterMusic, setFilterMusic] = useState([...MUSICS]);

	useEffect(() => {
		setIsAnimating(true);
		const timer = setTimeout(() => {
			setIsAnimating(false);
		}, 900);
		if (music.click === false) {
			setIsMusicList(false);
			setChoseGroup(false);
		}
		return () => clearTimeout(timer);
	}, [music.click]);

	useEffect(() => {
		const dispose = reaction(
			() => likuStore.shuffleMusic,
			(random) => {
				if (random) {
					const randomIndex = Math.floor(Math.random() * filterMusic.length);
					const curIndex = randomIndex - 1;

					setSelectedIndex(curIndex);
					likuStore.playJson(filterMusic[randomIndex].data);
				}
			},
		);

		return () => dispose();
	}, [filterMusic]);

	useEffect(() => {
		localStorage.setItem('isShuffle', isShuffle);
	}, [isShuffle]);

	useEffect(() => {}, [likuStore.isMusic]);

	useEffect(() => {
		if (isPlay === true && likuStore.isMusic === false) {
			const curIndex = selectedIndex + 1;

			if (filterMusic.length === curIndex) {
				likuStore.playJson(filterMusic[0].data);
				setSelectedIndex(0);
			} else {
				likuStore.playJson(filterMusic[curIndex].data);
				setSelectedIndex(curIndex);
			}
			likuStore.setIsMusic(true);
		}
	}, [likuStore.isMusic]);

	useEffect(() => {
		if (!isVisible) {
			setIsGroup(false);
			setIsMusicList(false);
		}
	}, [isVisible]);

	const groupList = Object.keys(GROUPS)
		.filter((key) =>
			isSenior
				? key === 'TROT'
					? true
					: false
				: key === 'TROT'
				? false
				: true,
		)
		.map((key) => (
			<li
				key={key}
				onClick={() => {
					if (key !== choseGroup) {
						setChoseGroup(key);
						let filterList = [...MUSICS];
						if (key !== 'ALL') {
							filterList = filterList.filter((music) => music.group === key);
						}

						setFilterMusic(filterList);
					} else {
						setChoseGroup(null);
						setFilterMusic([...MUSICS]);
					}
				}}>
				{GROUPS[key]}
			</li>
		));

	const musicList = filterMusic.map((music, index) => (
		<li
			key={`${music.name}_${index}`}
			onClick={() => {
				setSelectedIndex(index);
				if (music !== choseMusic) setChoseMusic(music);
				else setChoseMusic(null);
			}}>
			{music.name}
		</li>
	));

	const setIsMusic = (value) => {
		console.log(
			'likuStore.thisLiku.motionStatus----------',
			likuStore.thisLiku.motionStatus,
		);
		if (likuStore.thisLiku.motionStatus === 'active' && choseMusic !== null) {
			if (choseMusic) {
				if (value) {
					likuStore.stop();
					likuStore.playJson(choseMusic?.data);
				} else {
					likuStore.stop();
				}
				likuStore.setIsMusic(value);
			}
		} else if (likuStore.thisLiku.motionStatus !== 'active') {
			alert('리쿠를 깨운 후 다시 시도해주세요.');
		} else {
			alert('노래를 선택해주세요.');
		}
	};

	const setShuffle = () => {
		localStorage.setItem('isShuffle', isShuffle);
	};

	return (
		<S.Content isVisible={isVisible} isAnimating={isAnimating}>
			<Image
				custom={S.CustomImage}
				srcArray={likuStore.isMusic ? S.music_on_list : S.music_off_list}
			/>
			<S.GroupSelectBox click={isGroup} onClick={() => setIsGroup()}>
				{isGroup && <ul>{groupList}</ul>}
				<span className='selected'>{GROUPS[choseGroup] || '그룹 선택'}</span>
			</S.GroupSelectBox>
			<S.GroupSelectBox click={isMusicList} onClick={() => setIsMusicList()}>
				{isMusicList && <ul>{musicList}</ul>}
				<span className='selected'>
					{filterMusic[selectedIndex]?.name || '음악 선택'}
				</span>
			</S.GroupSelectBox>
			<S.ButtonGroup>
				<S.Button
					onClick={() => {
						setisPlay(true);
						setIsMusic(true);
					}}>
					<Image srcArray={S.play_list} />
				</S.Button>
				<S.Button
					onClick={() => {
						setisPlay(false);
						setIsMusic(false);
					}}>
					<Image srcArray={S.stop_list} />
				</S.Button>
				<S.Button click={isShuffle} onClick={() => setIsShuffle()}>
					<Image srcArray={S.shuffle_list} />
				</S.Button>
			</S.ButtonGroup>
		</S.Content>
	);
});

export default DockLikuMusic;
