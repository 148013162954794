import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import * as S from './AlarmMakeModal.style';
import { makeAlarm } from '../../../services/apis/CustomApi';
import { ACTIONS, DISPLAYS } from '../../../data/motion';
import { RootStoreContext } from '../../../App';
import { useForm, SubmitHandler } from 'react-hook-form';
import DateInput from '../../../components/input/DateInput';
import TimeInput from '../../../components/input/TimeInput';
import UserSelect from '../../../components/input/UserSelect';

const date = new Date();
const formattedDate = date.toISOString().slice(0, 10);

function formatted(dateStr) {
	let parts = dateStr.split('-');
	let month = parts[1].padStart(2, '0');
	let day = parts[2].padStart(2, '0');
	let formattedDate = `${parts[0]}-${month}-${day}`;
	return formattedDate;
}
const AlarmFixModal = observer(
	({ defaultValue, setFixModal, category = 'medicine', onLoad }) => {
		const { alarmStore } = useContext(RootStoreContext);
		console.log('defaultValue', defaultValue);
		const [days, setDays] = useState([
			defaultValue?.day[0] || 'x',
			defaultValue?.day[1] || 'x',
			defaultValue?.day[2] || 'x',
			defaultValue?.day[3] || 'x',
			defaultValue?.day[4] || 'x',
			defaultValue?.day[5] || 'x',
			defaultValue?.day[6] || 'x',
		]);

		const [isError, setIsError] = useState(false);
		const [faceUserIds, setFaceUserIds] = useState(
			defaultValue?.face_users?.map((face_user) => face_user.id) || [],
		);
		const { register, handleSubmit, watch, setValue } = useForm({
			defaultValues: {
				start_date: defaultValue?.start_date.split('T')[0],
				end_date: defaultValue?.end_date.split('T')[0],
				start_time: defaultValue?.start_time,
				end_time: defaultValue?.end_time,
				time: defaultValue?.time,
				days: [
					defaultValue?.day[0] || 'x',
					defaultValue?.day[1] || 'x',
					defaultValue?.day[2] || 'x',
					defaultValue?.day[3] || 'x',
					defaultValue?.day[4] || 'x',
					defaultValue?.day[5] || 'x',
					defaultValue?.day[6] || 'x',
				],
			},
		});

		const toggleDay = (index) => {
			setDays((state) => {
				const tempState = [...state];
				tempState[index] = days[index] === 'o' ? 'x' : 'o';
				return tempState;
			});
		};

		const onSubmit = async (data) => {
			if (faceUserIds.length > 0) {
				const startDate = formatted(data.start_date) + 'T00:00:00';
				const endDate = formatted(data.end_date) + 'T00:00:00';

				await alarmStore.updateAlarm({
					id: defaultValue.id,
					category,
					name: data.name,
					info: data.name,
					start_date: startDate || formattedDate,
					end_date: endDate || formattedDate,
					start_time: '00:00',
					end_time: '23:59',
					time: data.time || '00:00',
					day: days.join(''),
					repeat: '',
					target: 'Someone',
					scenario_id_array: [],
					face_user_id_array: faceUserIds,
					priority: 150,
					state: true,
				});
				setFixModal(false);
				onLoad();
				setIsError(false);
			} else {
				setIsError(true);
			}
		};

		const onDelete = async (data) => {
			const startDate = data.start_date + 'T00:00:00';
			const endDate = data.end_date + 'T00:00:00';

			await alarmStore.deleteAlarm({
				id: defaultValue.id,
				category,
				name: data.name,
				info: data.name,
				start_date: startDate || formattedDate,
				end_date: endDate || formattedDate,
				start_time: '00:00',
				end_time: '23:59',
				time: data.time || '00:00',
				day: days.join(''),
				repeat: '',
				target: 'Someone',
				scenario_id_array: [],
				face_user_id_array: faceUserIds,
				priority: 150,
				state: true,
			});
			setFixModal(false);
			onLoad();
		};

		return (
			<S.Container>
				<S.Header>
					<S.CloseButton onClick={() => setFixModal(false)}>+</S.CloseButton>
					<p>알림 설정</p>
					<form onSubmit={handleSubmit(onDelete)}>
						<S.DeleteButton type='submit'>삭제</S.DeleteButton>
					</form>
					<form onSubmit={handleSubmit(onSubmit)}>
						<S.SaveButton type='submit'>수정</S.SaveButton>
					</form>
				</S.Header>
				<S.Content>
					<S.AlarmContent>
						<div className='title'>
							<input
								placeholder='알람 이름을 입력하세요'
								type='text'
								defaultValue={defaultValue?.name}
								{...register('name')}
							/>
						</div>
						<div className='time cell'>
							<div className='content_flex'>
								<p className='cellTitle'>알림 시간</p>
								{/*<div className='switch_wrapper'>*/}
								{/*	<input*/}
								{/*		className='switch_input'*/}
								{/*		type='checkbox'*/}
								{/*		id={'make_modal_switch2'}*/}
								{/*	/>*/}
								{/*	<label*/}
								{/*		htmlFor={'make_modal_switch2'}*/}
								{/*		className='switch_label'>*/}
								{/*		<span className='onf_btn'></span>*/}
								{/*	</label>*/}
								{/*</div>*/}
							</div>
							<div className='timeBox'>
								<TimeInput
									value={defaultValue.time}
									id={'time'}
									setValue={setValue}
								/>
							</div>
						</div>
						<div className='date cell'>
							<div className='content_flex'>
								<p className='cellTitle'>활성화 기간</p>
								{/*<div className='switch_wrapper'>*/}
								{/*	<input*/}
								{/*		className='switch_input'*/}
								{/*		type='checkbox'*/}
								{/*		id={'make_modal_switch1'}*/}
								{/*	/>*/}
								{/*	<label*/}
								{/*		htmlFor={'make_modal_switch1'}*/}
								{/*		className='switch_label'>*/}
								{/*		<span className='onf_btn'></span>*/}
								{/*	</label>*/}
								{/*</div>*/}
							</div>
							<div className='timeBox'>
								<DateInput
									value={defaultValue?.start_date.split('T')[0] || undefined}
									id={'start_date'}
									setValue={setValue}
								/>
								<span>부터</span>
								<DateInput
									value={defaultValue?.end_date.split('T')[0] || undefined}
									id={'end_date'}
									setValue={setValue}
								/>
								<span>까지</span>
							</div>
							<div className='dayButtons'>
								{['월', '화', '수', '목', '금', '토', '일'].map(
									(day, index) => {
										// const dayValues = watch('days');
										// console.log('dayValues[index]', dayValues[index]);
										return (
											<label
												key={index}
												id={`day_${index}`}
												onClick={() => toggleDay(index)}
												className={days[index] === 'o' ? 'day clicked' : 'day'}>
												{day}
												<input
													id={`day_${index}`}
													key={index}
													type='hidden'
													value={days[index] === 'o' ? 'o' : 'x'}
													{...register(`days[${index}]`)}
												/>
											</label>
										);
									},
								)}
							</div>
						</div>
						<div className='user cell'>
							<div>
								<p className='cellTitle'>
									알람 대상 설정
									{isError && (
										<span className='error'>사용자를 선택해주세요.</span>
									)}
								</p>
							</div>
							<div className='userSelect'>
								<label htmlFor='user-selections' className='user-selections'>
									<UserSelect
										users={alarmStore.users}
										id={'alarm-update-user-selection'}
										setFaceUserIds={setFaceUserIds}
									/>
								</label>
								{faceUserIds.map((user, index) => {
									return (
										<div
											key={index}
											className='alarm-user'
											onClick={() => {
												setFaceUserIds((state) =>
													state.filter((item) => item !== user),
												);
											}}>
											{
												alarmStore.users?.filter((alarmUser) => {
													console.log(
														alarmUser,
														alarmUser.id,
														+user,
														alarmUser.id == user,
													);
													return alarmUser.id == user;
												})[0]?.name
											}
										</div>
									);
								})}
							</div>
						</div>
					</S.AlarmContent>
				</S.Content>
			</S.Container>
		);
	},
);

export default AlarmFixModal;
