import { makeAutoObservable, reaction } from 'mobx';
import KorToEn from '../../utils/KorToEn';
import { sleep } from '../../utils/Sleep';

class InteractiveV2Store {
	rootStore;

	contents = null;

	components = [];

	sequenceNodes = [];
	backgroundNodes = [];
	defaultNodes = [];

	edges = [];

	sequenceIndex = -1;
	backgroundIndex = -1;
	currentIndex = -1;

	conditions = [];

	aruco = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;

		this.components = [];

		this.sequenceNodes = [];
		this.backgroundNodes = [];
		this.defaultNodes = [];

		this.edges = [];

		this.sequenceIndex = -1;
		this.backgroundIndex = -1;
		this.currentIndex = -1;

		this.conditions = [];

		this.aruco = null;

		// TODO : sequenceIndex로만 되어있어서 만약 (sequence)-(background)-(current)순대로 했을때
		// 1-0-0 이라고 하면
		// reaction(
		// 	() => this.currentIndex,
		// 	() => {
		// 		console.log('reaction currentIndex');
		// 		this.rootStore.playerControlV2Store.likuPlay();
		// 	},
		// );

		reaction(
			() =>
				`${this.sequenceIndex}_${this.backgroundIndex}_${this.currentIndex}`,
			() => {
				this.rootStore.playerControlV2Store.likuPlay();
			},
		);

		// reaction(
		// 	() => this.backgroundIndex,
		// 	() => {
		// 		console.log('reaction backgroundIndex');
		// 		this.rootStore.playerControlV2Store.likuPlay();
		// 	},
		// );

		// reaction(
		// 	() => this.conditions,
		// 	(newConditions) => {
		// 		console.log('conditions reaction111', JSON.stringify(this.conditions));
		// 		console.log('conditions reaction222', JSON.stringify(newConditions));
		// 		// this.checkCondition();
		// 	},
		// );
	}

	init(info, data) {
		if (info && data) {
			this.setContents(info);
			this.setComponents(data.component);

			const sequence = data.node.filter((node) => {
				const isConnectEdge = data.edge.find(
					(edge) => edge.source === node.id || edge.target === node.id,
				);
				return node.type === 'sequenceNode' && isConnectEdge;
			});
			const background = data.node.filter(
				(node) => node.type === 'backgroundNode',
			);
			const _default = data.node.filter((node) => node.type === 'defaultNode');
			this.setSequenceNodes(sequence);
			this.setBackgroundNodes(background);
			this.setDefaultNodes(_default);

			this.setEdges(data.edge);

			this.setArucoData(data.aruco);
		}
	}

	initIndex(value) {
		this.sequenceIndex = value;
		this.backgroundIndex = value;
		this.currentIndex = value;
	}

	setContents(data) {
		this.contents = data;
	}

	setComponents(data) {
		this.components = data;
	}

	setSequenceNodes(nodes) {
		this.sequenceNodes = nodes;
	}

	setBackgroundNodes(nodes) {
		this.backgroundNodes = nodes;
	}

	setDefaultNodes(nodes) {
		this.defaultNodes = nodes;
	}

	setEdges(data) {
		this.edges = data;
	}

	setArucoData(data) {
		delete data?.aruco_precision;
		this.aruco = data;
	}

	setSequenceIndex(value = this.sequenceIndex + 1) {
		this.sequenceIndex = value;
	}

	setBackgroundIndex(value = this.backgroundIndex + 1) {
		this.backgroundIndex = value;
	}

	setCurrentIndex(value = this.currentIndex + 1) {
		this.currentIndex = value;
	}

	getCurrentSequence() {
		return this.sequenceNodes[this.sequenceIndex] || [];
	}

	getBackgrounds() {
		const currentSequence = this.getCurrentSequence();
		return currentSequence
			? this.backgroundNodes.filter(
					(node) => node.parentId === currentSequence.id,
			  )
			: [];
	}

	getDefaults(currentBackground) {
		return currentBackground
			? this.defaultNodes.filter(
					(node) => node.parentId === currentBackground.id,
			  )
			: [];
	}

	getCurrentNodes() {
		const currentSequence = this.getCurrentSequence();
		const backgrounds = this.getBackgrounds();
		const currentBackground = backgrounds[this.backgroundIndex];
		const defaults = this.getDefaults(currentBackground);
		const currentDefault = defaults[this.currentIndex];

		return {
			currentSequence,
			backgrounds,
			currentBackground,
			defaults,
			currentDefault,
		};
	}

	setConditions(type, value) {
		if (type) {
			this.conditions = [...this.conditions, { type, value }];
			this.checkCondition();
		} else {
			this.conditions = [];
		}
	}

	areSetAndArrayEquivalent(set1, arr2) {
		if (set1.size !== arr2.length) return false;

		for (let item of set1) {
			if (!arr2.includes(item)) return false;
		}
		return true;
	}

	checkSound(edges) {
		let andPool = [];
		let targetEdge = null;

		for (let edge of edges) {
			const isAndCondition = edge.data[0].value.includes('&');
			const isOrCondition = edge.data[0].value.includes('|');
			const orPool = edge.data[0].value.split('|');

			if (
				isAndCondition &&
				andPool.includes(this.conditions[this.conditions.length - 1].value)
			) {
				andPool = [
					...andPool,
					this.conditions[this.conditions.length - 1].value,
				];
				const isEqual = this.areSetAndArrayEquivalent(
					andPool,
					edge.data[0].value.split('&'),
				);
				if (isEqual) {
					targetEdge = edge;
					break;
				}
			}

			if (
				isOrCondition &&
				orPool.includes(this.conditions[this.conditions.length - 1].value)
			) {
				targetEdge = edge;
				break;
			}

			if (
				edge.data?.[0]?.type ===
					this.conditions?.[this.conditions.length - 1]?.type &&
				edge.data?.[0]?.value ===
					this.conditions?.[this.conditions.length - 1]?.value
			) {
				targetEdge = edge;
				break;
			} else if (this.conditions?.[this.conditions.length - 1]?.value === '?') {
				const message = {
					action: {
						action_name: 'None',
						action_type: 'overlap',
						pitch: 0,
						yaw: 0,
					},
					display: {
						display_name: 'None',
						delay: 0,
						playtime: 1,
						playspeed: 1,
					},
					speech: {
						speech_name: 'TTS_output',
						TTS: '다시 말해줄래?',
						delay: 0,
						repeat: 1,
					},
					listen: 'transcript',
				};

				const englishName = KorToEn('리쿠');
				this.rootStore.mqttStore?.playerMqtt.playMotion(
					this.rootStore.playerControlV2Store.executionUuid,
					englishName,
					message,
				);
				this.setConditions();
			}
		}

		if (!targetEdge && this.conditions.length > 0) {
			const failNext = edges.find((edge) => edge.data[0].value === '오답');
			targetEdge = failNext;
		}

		return targetEdge;
	}

	checkCondition() {
		const edges = this.edges.filter((edge) => {
			return this.sequenceNodes[this.sequenceIndex].id === edge.source;
		});
		const isRandom = this.isRandom(edges);
		const isSound = this.isSound(edges);
		const isAruco = this.isAruco(edges);
		const isTouch = this.isTouch(edges);
		const isNumber = this.isNumber(edges);
		if (edges.length > 0 && this.conditions.length > 0) {
			let nextPoolEdge = null;
			if (isSound) {
				nextPoolEdge = this.checkSound(edges);
			} else {
				nextPoolEdge = edges.find((edge) => {
					let isConditionMet = this.conditions.every((condition) =>
						edge.data.some((data) => {
							return (
								condition.type === data.type.toLowerCase() &&
								condition.value === data.value
							);
						}),
					);
					return isConditionMet && edge;
				});
			}

			if (nextPoolEdge) {
				const index = this.sequenceNodes.findIndex(
					(node) => node.id === nextPoolEdge.target,
				);
				this.setConditions();
				this.setSequenceIndex(index);
				this.setBackgroundIndex(0);
				this.setCurrentIndex(0);
			}
		} else if (edges.length > 0) {
			if (isRandom.length > 0) {
				this.setRandom(isRandom);
			} else if (isSound) {
				this.setSound();
			} else if (isAruco.length > 0) {
				this.setAruco(isAruco);
			} else if (isTouch) {
				this.setTouch(isTouch);
			} else if (isNumber) {
				this.setNumber(isNumber);
			}
		}
	}

	checkEdge() {
		const edges = this.edges.filter(
			(edge) => this.sequenceNodes[this.sequenceIndex].id === edge.source,
		);

		if (edges.length > 0) {
			const notCondition = edges.filter((edge) => !edge.data?.length);
			if (notCondition.length > 0) {
				const nextPoolIndex = this.sequenceNodes.findIndex((node, index) => {
					if (node.id === notCondition[0]?.target) return index;
				});

				this.setSequenceIndex(nextPoolIndex);
				this.setBackgroundIndex(0);
				this.setCurrentIndex(0);
			} else {
				this.checkCondition();
			}
		} else {
			this.rootStore.playerControlV2Store.stop();
		}
	}

	setNextPool() {
		const { defaults, currentDefault, backgrounds } = this.getCurrentNodes();
		if (!currentDefault) {
			this.setSequenceIndex();
			this.setBackgroundIndex();
			this.setCurrentIndex();
		} else {
			if (defaults.length > this.currentIndex + 1) {
				this.setCurrentIndex();
			} else if (backgrounds.length > this.backgroundIndex + 1) {
				this.setBackgroundIndex();
				this.setCurrentIndex(0);
			} else {
				this.checkEdge();
			}
		}
	}

	isArucoCoding() {
		if (this.contents.title.includes('코딩')) {
			return true;
		} else {
			return false;
		}
	}

	isBirthday() {
		if (this.contents?.title?.includes('생일')) {
			return true;
		} else {
			return false;
		}
	}

	isRandom(edges) {
		const randoms = edges.filter((edge) => {
			return (
				edge.data?.length <= 1 &&
				edge.data?.[0]?.type.toLowerCase() === 'random'
			);
		});

		return randoms;
	}

	setRandom(randoms) {
		if (randoms.length > 0) {
			const randomIndex = Math.floor(Math.random() * randoms.length);
			const randomEdgeId = randoms[randomIndex];

			const nextPoolIndex = this.sequenceNodes.findIndex(
				(node) => node.id === randomEdgeId.target,
			);
			this.setSequenceIndex(nextPoolIndex);
			this.setBackgroundIndex(0);
			this.setCurrentIndex(0);

			return true;
		}

		return false;
	}

	isNumber(edges) {
		const numbers = edges.filter((edge) => {
			return (
				edge.data?.length <= 1 &&
				edge.data?.[0]?.type.toLowerCase() === 'number'
			);
		});

		return numbers;
	}
	setNumber(edges) {
		if (this.isBirthday()) {
			const childNumber = this.rootStore.birthdayStore.selectedChildList.length;
			const curEdge = edges.filter(
				(edge) => edge.data?.[0]?.value == childNumber,
			);
			if (curEdge.length) {
				const nextIndex = this.sequenceNodes.findIndex(
					(node) => node.id == curEdge[0].target,
				);
				this.setSequenceIndex(nextIndex);
				this.setBackgroundIndex(0);
				this.setCurrentIndex(0);
				return true;
			}
		} else {
			this.setSequenceIndex();
			this.setBackgroundIndex(0);
			this.setCurrentIndex(0);
			return true;
		}
		return false;
	}

	isSound(edges) {
		const sound = edges.find((edge) =>
			edge.data.find((data) => data.type.toLowerCase() === 'sound'),
		);

		return sound;
	}

	setSound() {
		if (!this.rootStore.playerControlV2Store.isSoundMode) {
			try {
				this.rootStore.playerControlV2Store.setIsSoundMode(true);
				this.rootStore.mqttStore.playerMqtt.setListenText(
					this.rootStore.playerControlV2Store.setSoundInput.bind(this),
				);

				const message = {
					action: {
						action_name: 'None',
						action_type: 'overlap',
						pitch: 0,
						yaw: 0,
					},
					display: {
						display_name: 'None',
						delay: 0,
						playtime: 1,
						playspeed: 1,
					},
					speech: {
						speech_name: 'empty_p1',
						TTS: '',
						delay: 0,
						repeat: 1,
					},
					listen: 'transcript',
				};

				const englishName = KorToEn('리쿠');
				this.rootStore.mqttStore?.playerMqtt.playMotion(
					this.rootStore.playerControlV2Store.executionUuid,
					englishName,
					message,
				);

				return true;
			} catch (e) {
				console.log('setSound Error!!! : ', e);
				return false;
			}
		}
	}

	isAruco(edges) {
		const aruco = edges.filter((edge) =>
			edge.data.find((data) => data.type.toLowerCase() === 'aruco'),
		);

		return aruco;
	}

	async setAruco(edges) {
		if (!this.rootStore.playerControlV2Store.isArucoMode) {
			this.rootStore.playerControlV2Store.setIsArucoMode(true);

			const isConditionNext = edges.find((edge) =>
				edge.data.find((data) => data.value !== ''),
			);

			this.rootStore.mqttStore.playerMqtt.setArucoMode(
				this.aruco,
				this.rootStore.playerControlV2Store.setArucoInput.bind(
					this,
					isConditionNext,
				),
			);
			await sleep(10000);
		}
	}

	isTouch(edges) {
		const touch = edges.find((edge) =>
			edge.data.find((data) => data.type.toLowerCase() === 'touch'),
		);

		return touch;
	}

	setTouch(edge) {
		if (!this.rootStore.playerControlV2Store.isTouchMode) {
			this.rootStore.playerControlV2Store.setIsTouchMode(true);

			this.rootStore.mqttStore.playerMqtt.setTouchMode(
				'start',
				this.rootStore.playerControlV2Store.setTouchInput.bind(this),
			);
		} else if (
			edge.data[0].value === this.rootStore.playerControlV2Store.touchInput
		) {
			this.rootStore.playerControlV2Store.setTouchAruco();
		}
	}

	clear() {
		this.components = [];

		this.sequenceNodes = [];
		this.backgroundNodes = [];
		this.defaultNodes = [];

		this.edges = [];

		this.sequenceIndex = -1;
		this.backgroundIndex = -1;
		this.currentIndex = -1;
	}
}

export default InteractiveV2Store;
