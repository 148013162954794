import styled, { css } from 'styled-components';

import music_on from '../../../assets/image/music/music-on.svg';
import music_off from '../../../assets/image/music/music-off.svg';

// import play from '../../../assets/image/play/play.webp';
// import play2x from '../../../assets/image/play/play@2x.webp';
// import play3x from '../../../assets/image/play/play@3x.webp';
import playPng from '../../../assets/image/play/play.png';

// import stop from '../../../assets/image/stop/stop.webp';
// import stop2x from '../../../assets/image/stop/stop@2x.webp';
// import stop3x from '../../../assets/image/stop/stop@3x.webp';
import stopPng from '../../../assets/image/stop/stop.png';

// import shuffle from '../../../assets/image/shuffle/shuffle.webp';
// import shuffle2x from '../../../assets/image/shuffle/shuffle@2x.webp';
// import shuffle3x from '../../../assets/image/shuffle/shuffle@3x.webp';
import shufflePng from '../../../assets/image/shuffle/shuffle.png';

const play_list = [playPng];
const stop_list = [stopPng];
const shuffle_list = [shufflePng];
const music_on_list = [music_on];
const music_off_list = [music_off];

export { music_on_list, music_off_list, play_list, stop_list, shuffle_list };

export const Content = styled.div`
	//display: static;
	position: relative;
	z-index: 8888;
	border-radius: 12px;
	background-color: #fff;
	transform: translate(-5rem, 8rem);
	//overflow: hidden;
	transition-property: width, height, padding, transform;
	pointer-events: auto;
	min-width: 567px;
	@keyframes moveXXIn {
		0% {
			transform: translateX(-5rem);
		}
		70% {
			transform: translateX(-4rem);
		}
		100% {
			transform: translateX(0);
		}
	}
	@keyframes moveXXOut {
		from {
			transform: translateX(0);
		}
		to {
			display: none;
			transform: translateX(-5rem);
		}
	}

	border: ${({ theme }) => `solid 1px ${theme.color.gray_f4}`};
	overflow: ${({ isAnimating }) => (isAnimating ? 'hidden' : 'visible')};

	${({ theme }) => theme.common.flex('row', 'flex-end', 'space-between')};
	${({ isVisible }) =>
		isVisible
			? `width: 100%;
				height: 70%;
				margin-bottom: 1.2rem;
				padding: 1.2rem 2.4rem 1.2rem 1.2rem;
         animation: moveXXIn 0.3s ease-out forwards;
				transform: translate(0, 0);
				transition-duration: 0s, 0.6s, 0.7s, 0.7s;
				transition-delay: 0.3s, 0s, 0s, 0s;
				transition-timing-function: ease-out, ease-in-out;`
			: `
				visibility: hidden;
				opacity: 0;
				width: 0;
				height: 0;
				padding: 0;
				transform: translateX(-5rem);
				transition: opacity 0.3s ease-out, width 0.6s ease, height 0.7s ease;
			`}
`;

export const CustomImage = css`
	img {
		aspect-ratio: 1 / 1;
		min-height: 2.4rem;
		${({ theme }) => theme.common.wh('auto', '100%')};

		&:hover {
			background-color: ${({ theme }) => `${theme.color.gray}`};
		}
	}
`;

export const SelectBox = styled.div`
	position: relative;
	border-radius: 5px;
	background-color: #fff;
	//padding: 0.3rem 0.8rem;
	font-size: 1.4rem;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: left;
	transition: max-height 1.5s ease;
	border: ${({ theme }) => `solid 1px ${theme.color.gray_f4}`};
	max-height: ${({ click }) => (click ? '50vh' : '100%')};
	${({ theme }) => theme.common.flex('column', 'end', 'center')};
	padding: 0.4rem 0.8rem;

	.selected {
		white-space: nowrap;
		font-family: NotoSansKR-Bold;
		font-weight: bold;
		color: #46423d;
		justify-content: center;
		${({ theme }) => theme.common.wh('100%')};

		&:hover {
			${({ theme, click }) =>
				!click && `background-color: ${theme.color.gray}`};
		}
	}

	ul {
		overflow-y: auto;
		transition: all 0.2s ease;
		z-index: 2;
		font-family: NotoSansKR-Regular;
		font-size: 1.4rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		border-radius: 4px;
		background-color: transparent;
		${({ theme }) => theme.common.flex('column', 'flex-end', 'start')};
		${({ theme }) => theme.common.wh('100%', 'auto')};
		gap: 10px;
		margin-bottom: 10px;
	}

	li {
		padding: 0.3rem 0rem;
		font-size: 1.5rem;
		font-family: NotoSansKR-Regular;
		font-weight: normal;
		color: #9a9a9a;
		background-color: transparent;
		${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
		${({ theme }) => theme.common.wh('100%', '2.4rem')};

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			font-family: NotoSansKR-Bold;
			font-weight: bold;
			color: #46423d;
		}
	}
`;

export const GroupSelectBox = styled(SelectBox)`
	${({ theme }) => theme.common.wh('25%', 'auto')};
	min-height: 100%;
	font-size: 1.4rem;
	min-width: 8rem;
	ul {
		overflow-y: scroll;

		::-webkit-scrollbar {
			width: 12px;
			border-left: 1px solid ${({ theme }) => theme.color.gray};
		}
		::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.color.darkgray};
			border-radius: 4px;
			&:hover {
				background-color: ${({ theme }) => theme.color.lightgray};
			}
		}
	}
	span {
		font-size: 1.6rem;
		height: 100%;
	}
`;

export const MusicSelectBox = styled(SelectBox)`
	${({ theme }) => theme.common.wh('25%', 'auto')};
	font-size: 1.4rem;
	min-width: 8rem;
	overflow: hidden;
	ul {
		display: block;
		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 12px;
			border-left: 1px solid ${({ theme }) => theme.color.gray};
		}
		::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.color.darkgray};
			border-radius: 4px;
			&:hover {
				background-color: ${({ theme }) => theme.color.lightgray};
			}
		}
	}
`;

export const ButtonGroup = styled.div`
	position: relative;
	display: flex;
	justify-content: end;
	align-items: center;
	height: 100%;
	gap: 30px;
`;

// 567px
export const Button = styled.button`
	aspect-ratio: 1 / 1;
	min-height: 2.4rem;
	${({ theme }) =>
		theme.common.setHeightResponsive({
			mobile: '50%',
			tablet: '50%',
			desktop: '100%',
		})}
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	padding: 12px 0;
	${CustomImage};
	background-color: ${({ theme, click }) => {
		// console.log('isShuffle', click);
		if (click) {
			return theme.color.gray;
		} else {
			return 'transparent';
		}
	}};

	// ${({ theme, click }) => click && `background-color: ${theme.color.gray}`}
`;
