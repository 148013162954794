import { makeAutoObservable, reaction, runInAction } from 'mobx';

/**
 * player 페이지의 영상에 필요한 정보를 담은 스토어
 */
class VideoStore {
	rootStore;
	content = null;
	/**
	 * 컨텐츠의 로딩 상태
	 * @type {string}
	 * @description
	 * 'idle' - 대기 상태, 아무것도 로딩하지 않고 있을 때
	 * 'loadingCurrent' - 현재 액션블럭을 로딩 중일 때
	 * 'loadingNext' - 다음 액션블럭을 미리 로딩 중일 때
	 * 'loaded' - 모든 컨텐츠 로딩 완료
	 */
	loadingState = 'idle';
	/**	컨텐츠의 재생 상태 */
	playState = { isPlay: false, speed: 1, fullscreen: false };
	/** 컨텐츠의 현재 재생 씬 0 : 초기상태 */
	sceneNumber = -1;

	/** 비디오 컨스트럭터 */
	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.content = null;
		this.loadingState = 'idle';
		this.playState = { isPlay: false, speed: 1, fullscreen: false };
		this.sceneNumber = -1;

		// reaction(
		// 	() => this.sceneNumber,
		// 	(sceneNumber) => {
		// 		this.rootStore.playerControlStore.likuPlay();
		// 	},
		// );

		reaction(
			() => this.sceneNumber,
			(sceneNumber) => {
				if (
					this.isInteractive() &&
					(!this.rootStore.interactiveStore?.nodes ||
						!this.rootStore.interactiveStore?.nodes?.length)
				) {
					return;
				}

				this.rootStore.playerControlStore.likuPlay();
			},
		);

		reaction(
			() => this.sceneNumber,
			(sceneNumber) => {
				const curTTS = this.content?.data[sceneNumber][0]?.motion?.speech?.TTS;
				if (!this.isBirthday()) return;
				if (!curTTS?.includes('!-^-!') && !curTTS?.includes('!^~^!')) return;
				let startIndex;
				let endIndex;
				let finalEndIndex;
				const tempTTS = curTTS
					?.split('(')[1]
					?.split([')'])[0]
					?.split('|')
					.map((item) => {
						return item.split(',');
					});
				const childLength =
					this.rootStore.birthdayStore?.selectedChildList?.length;
				const ranNum = Math.floor(Math.random() * 4) + 1;

				let indicate;
				if (curTTS?.includes('!-^-!')) {
					indicate = childLength;
				} else if (curTTS?.includes('!^~^!')) {
					indicate = ranNum;
				}
				finalEndIndex =
					tempTTS.length == 4 ? +tempTTS[3][1] - 1 : +tempTTS[4][1] - 1;
				this.rootStore.birthdayStore.setFinalEndIndex(finalEndIndex);
				switch (indicate) {
					case 1:
						{
							startIndex = +tempTTS[0][0] - 1;
							endIndex = +tempTTS[0][1] - 1;
						}
						break;
					case 2:
						{
							startIndex = +tempTTS[1][0] - 1;
							endIndex = +tempTTS[1][1] - 1;
						}
						break;
					case 3:
						{
							startIndex = +tempTTS[2][0] - 1;
							endIndex = +tempTTS[2][1] - 1;
						}
						break;
					case 4:
						{
							startIndex = +tempTTS[3][0] - 1;
							endIndex = +tempTTS[3][1] - 1;
						}
						break;
					case 5:
						{
							startIndex = +tempTTS[4][0] - 1;
							endIndex = +tempTTS[4][1] - 1;
						}
						break;
					default:
						{
							startIndex = +tempTTS[0][0] - 1;
							endIndex = +tempTTS[0][1] - 1;
						}
						break;
				}

				this.rootStore.birthdayStore.setStartIndex(startIndex);
				this.rootStore.birthdayStore.setEndIndex(endIndex);
				this.setSceneNumber(startIndex);
			},
		);

		reaction(
			() => this.sceneNumber,
			(sceneNumber) => {
				if (this.content) {
					localStorage.setItem(
						`${this.rootStore.authStore?.auth?.id}-${this.content?.uuid}`,
						this.sceneNumber,
					);
				}
				if (sceneNumber == this.rootStore.birthdayStore.endIndex + 1) {
					const finalIndex = this.rootStore.birthdayStore.finalEndIndex + 1;
					this.setSceneNumber(finalIndex);
					// this.rootStore.playerControlStore.likuPlay();
				}
			},
		);
		// reaction(
		// 	() => this.playState.isPlay,
		// 	(isPlay) => {
		// 		console.log('reaction!');
		// 		if (!isPlay) {
		// 			// 리쿠 멈추는 MQTT
		// 		}
		// 	},
		// );
	}

	setContent(_content) {
		if (_content?.uuid === this.content?.uuid && _content !== null) return;
		runInAction(() => {
			console.log('setContent', _content);
			this.content = _content;
		});
	}

	setSceneNumber(_number = this.sceneNumber + 1) {
		runInAction(() => {
			this.sceneNumber = _number;
		});
		console.log('setSceneNumber', this.sceneNumber);
	}

	setComponent(_component) {
		runInAction(() => {
			this.component = _component;
		});
	}

	/**
	 * play controller에서 조절하는 player의 재생 상태설정
	 * togglePlay, toggleSpeed, toggleFullscreen으로 개별 설정도 가능하지만 한번에 조절하기 원할경우 사용
	 * isPlay, speed, fullscreen 순서
	 * @param {boolean} isPlay 재생 || 정지 상태
	 * @param {integer} speed 재생 속도
	 * @param {boolean} fullscreen 풀스크린 상태
	 */
	setPlayState(isPlay, speed, fullscreen) {
		runInAction(() => {
			this.playState = { isPlay, speed, fullscreen };
		});
	}

	/** 재생상태 토글 */
	togglePlay(curPlay = this.playState.isPlay) {
		runInAction(() => {
			// const curPlay = this.playState.isPlay;
			this.playState = { ...this.playState, isPlay: !curPlay };
		});
	}

	/** 재생속도 토글 x1 <<--->> x2 */
	toggleSpeed() {
		runInAction(() => {
			const curSpeed = this.playState.speed;
			this.playState = { ...this.playState, speed: curSpeed === 1 ? 2 : 1 };
		});
	}

	/** 전체화면 토글 */
	toggleFullscreen() {
		runInAction(() => {
			const curFullscreen = this.playState.fullscreen;
			this.playState = {
				...this.playState,
				fullscreen: !curFullscreen,
			};
		});
	}

	/**
	 * 컨텐츠의 로딩 상태를 설정하는 함수
	 * @param {string} state - 설정하려는 로딩 상태
	 *   - 'idle': 대기 상태, 아무것도 로딩하지 않고 있을 때
	 *   - 'loadingCurrent': 현재 액션블럭을 로딩 중일 때
	 *   - 'loadingNext': 다음 액션블럭을 미리 로딩 중일 때
	 *   - 'loaded': 모든 컨텐츠 로딩 완료
	 */
	setLoadingState(state) {
		runInAction(() => {
			this.loadingState = state;
		});
	}

	isInteractive() {
		if (this.content?.title?.includes('Interactive')) {
			return true;
		} else {
			return false;
		}
	}

	isNewVersion() {}

	isBirthday() {
		if (this.content?.title?.includes('생일')) {
			return true;
		} else {
			return false;
		}
	}

	isMetaverse() {
		if (this.content?.title.includes('(가상)')) {
			return true;
		} else {
			return false;
		}
	}

	isAien() {
		if (this.content?.tags.some((tag) => tag?.name === '아이엔')) {
			return true;
		} else {
			return false;
		}
	}

	isAruco() {
		if (this.rootStore.videoStore.content?.title.includes('코딩')) {
			return true;
		} else {
			return false;
		}
	}

	clear() {
		runInAction(() => {
			this.content = null;
			this.loadingState = 'idle';
			this.playState = { isPlay: false, speed: 1, fullscreen: false };
			this.sceneNumber = 0;
		});
	}
}

export default VideoStore;
