import axios from 'axios';
import * as Cookie from '../../utils/Cookie';

const BASE_URL = 'https://fastapi.torooc.net/liku';
const TEST_URL = 'https://fastapi-test.torooc.net/liku';
const LIKU_MASTER_TOKEN =
	'eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MDE1NDcxOTUsImlhdCI6MTYzNzU0NzE5NSwianRpIjoidG9yb29jQHRvcm9vYy5jb20ifQ.v75Jh0B796lE6CYt42LvECLtiUhLTH0K2cVq-4WvHh5uP4KIsjGvodvqgSp_kruaVD0GwAPKdd7NiKbVVOk1AA';

const currentDomain = window.location.hostname;
export const finalUrl = currentDomain.includes('torooc.net')
	? TEST_URL
	: BASE_URL;

const url = new URL(finalUrl);
const BASE_ORIGIN = `${url.protocol}//${url.host}`;

const axiosApi = (options) => {
	const api = axios.create({
		baseURL: `${finalUrl}`,
		headers: {
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		// withCredentials: true,
		...options,
	});
	return api;
};

const axiosAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${finalUrl}`,
		headers: {
			Authorization: `Bearer ${Cookie.get('token')}`,
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		// withCredentials: true,
		...options,
	});
	interceptors(api);
	return api;
};

const masterAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${finalUrl}`,
		headers: {
			Authorization: `Bearer ${LIKU_MASTER_TOKEN}`,
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		...options,
	});
	interceptors(api);
	return api;
};

const axiosJsonAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${finalUrl}`,
		headers: {
			Authorization: `Bearer ${Cookie.get('token')}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		...options,
	});
	interceptors(api);
	return api;
};

const interceptors = (instance) => {
	instance.interceptors.request.use(
		(config) => {
			const token = Cookie.get('token');

			config.headers = {
				authorization: token ? `Bearer ${token}` : null,
			};
			return config;
		},
		(error) => Promise.reject(error.response),
	);

	// 응답 인터셉터 추가
	instance.interceptors.response.use(
		(response) => {
			// 정상 응답 처리
			return response;
		},
		async (error) => {
			// 401 Unauthorized 오류 처리
			if (error.response && error.response.status === 401) {
				// 로그인 페이지로 리디렉션
				// Cookie.removeAll();
				// window.location = '/login';
			}

			// 다른 오류들은 그대로 전달
			return Promise.reject(error);
		},
	);
	return instance;
};

export const defaultApi = axiosApi();
export const authApi = axiosAuthApi();
export const authJsonApi = axiosJsonAuthApi();
export const masterApi = masterAuthApi();
export const createParams = (request) => {
	const params = new URLSearchParams();
	for (let key of Object.keys(request)) {
		if (request[key]) {
			// console.log('key', key);
			// console.log('request', request[key]);
			params.append(key, request[key]);
		}
	}

	return params;
};
