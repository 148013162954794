import React, { useEffect, useState } from 'react';
import { DefaultImage } from './Image.style';

const Image = ({ alt, srcArray, custom, onClick }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);
	const [convertedSrcArray, setConvertedSrcArray] = useState(srcArray);

	const handleImageLoaded = (e) => {
		setIsLoaded(true);
	};

	const handleImageError = (e) => {
		// console.log('handleImageError', e);
		setIsError(true);
	};

	useEffect(() => {}, [isLoaded]);

	useEffect(() => {
		const convertedArray = srcArray.map((src) => {
			if (!src || typeof src !== 'string') {
				return src; // src가 null, undefined 또는 string이 아닐 경우 그대로 반환
			}
			// const replacedSrc = src.replace(
			// 	/torooc-storage-fastapi\.s3\.amazonaws\.com/g, // 정규식으로 대체
			// 	's3-proxy.torooc.net',
			// );
			const addedSrc = 'https://s3-proxy.torooc.net/' + src;
			const srcRegex =
				/^(?!.*https:\/\/)(?!.*http:\/\/)(?!.*static\/)(?!.*base64).*/;
			return srcRegex.test(src) ? addedSrc : src;
		});

		setConvertedSrcArray(convertedArray);
	}, [srcArray]);

	return (
		<>
			{/* 스켈레톤 처리 파트 */}
			{!isLoaded && !isError && (
				<div className={''} style={{ width: '99.9%', height: '99.9%' }}></div>
			)}

			{/* 만약 onLoad를 통해 로드 되었다면 isLoaded = true.
				이후 className을 통해 opacity 조절로 부드럽게 보여줌
			*/}
			{
				<DefaultImage
					onClick={onClick}
					onMouseEnter={(e) => e.target.classList.add('hovered')}
					onMouseLeave={(e) => e.target.classList.remove('hovered')}
					draggable={false}
					custom={custom}
					alt={alt}
					srcSet={`${convertedSrcArray?.[1] || convertedSrcArray?.[0]} 2x,
					 ${convertedSrcArray?.[2] || convertedSrcArray?.[0]} 3x`}
					// sizes={}
					src={convertedSrcArray?.[0] || null}
					onLoad={handleImageLoaded}
					onError={handleImageError}
					loading='lazy'
					// decoding='async'
					// fetchpriority='high'
					className={isLoaded ? 'isLoaded' : ''}
				/>
			}
		</>
	);
};

export default Image;
