/**
 * react Query
 * @module Query
 */

import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';

export const api = async (url, options) => {
	try {
		const { data } = await axios(url, options);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const useUrlQuery = (queryKey, url, options) => {
	return useQuery(queryKey, () => api(url), {
		...options,
		onSuccess: (data) => {
			console.log('useUrlQuery', url, data);
			return data;
		},
		onError: (error) => {
			console.log('useUrlQuery error', error);
		},
		staleTime: 5 * 60 * 1000, // 5분 동안 refetch 방지
		cacheTime: 10 * 60 * 1000, // 10분 동안 캐시 유지
	});
};

export const useUrlPrefetchQuery = async (queryKey, url, options) => {
	const queryClient = useQueryClient();

	if (url) {
		await queryClient.prefetchQuery(queryKey, () => api(url), {
			...options,
			onSuccess: (data) => {
				return data;
			},
			onError: (error) => {},
			staleTime: 5 * 60 * 1000, // 5분 동안 refetch 방지
			cacheTime: 10 * 60 * 1000, // 10분 동안 캐시 유지
		});
	}
};
