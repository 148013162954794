export class Action {
	constructor({
		action_name = 'defaultArray',
		action_type = 'overlap',
		pitch = 0,
		yaw = 0,
	}) {
		this.action_name = action_name;
		this.action_type = action_type;
		this.pitch = pitch;
		this.yaw = yaw;
	}
}

export class Display {
	constructor({
		display_name = 'blink',
		delay = 0,
		playtime = 1,
		playspeed = 1,
	}) {
		console.log('likuMotion ,display_name', display_name);
		this.display_name = display_name;
		this.delay = delay;
		this.playtime = playtime;
		this.playspeed = playspeed;
	}
}

export class Speech {
	constructor({ speech_name = 'TTS_output', TTS = '', delay = 0, repeat = 1 }) {
		this.speech_name = speech_name;
		this.TTS = TTS;
		this.delay = delay;
		this.repeat = repeat;
	}
}

export class LikuMotion {
	action;
	display;
	speech;

	constructor(action, display, speech) {
		if (typeof action === 'string') {
			this.action = new Action({ action_name: action });
		} else {
			this.action = new Action(action);
		}

		if (typeof display === 'string') {
			const displayStr = JSON.stringify(display);
			this.display = new Display({ display_name: display.toString() });
		} else {
			this.display = new Display(display);
		}

		if (typeof speech === 'string') {
			this.speech = new Speech({ TTS: speech });
		} else {
			this.speech = new Speech(speech);
		}
	}

	toJson() {
		return JSON.stringify({
			action: this.action,
			display: this.display,
			speech: this.speech,
		});
	}

	toPayload() {
		return { data: [this] };
	}
}
