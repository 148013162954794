import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;

	// align-items: stretch; /* 아이템의 높이를 컨테이너에 맞춰 늘리기 */
	perspective-origin: bottom;
	z-index: 999;

	border: 2px solid rgba(230, 230, 230, 1);
	border-top: none;
	box-shadow: 2px 2px 2px rgba(160, 160, 160, 0.7);
	opacity: ${(isHovered) => (isHovered ? '1' : '0')};
	pointer-events: ${(isHovered) => (isHovered ? 'all' : 'none')};
	transition: 0.3s height ease-in-out, 0.1s width ease-in-out,
		0.3s opacity ease-in-out;

	@media screen and (max-width: ${({ theme }) => theme.size.mobile}) {
		display: none;
	}
`;

export const CategoryContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100vw;
	height: 20vh;
	background-color: ${({ theme }) => theme.color.gray};
`;

export const CategoryItemsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
	height: fit-content;
	justify-content: start;
	align-content: space-between;
	gap: 1rem;
	padding: 2rem calc(50vw - 550px);
	margin: 0 auto;
`;

export const BlackBackground = styled.div`
	display: block;
	position: fixed;
	top: 100px;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
	z-index: -9999;
	top: 150px;
`;
