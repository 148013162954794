import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
	/* background-color: ${({ theme }) => theme.color.gray};
	border: 1px solid darkgray;
	border-radius: 1.5rem; */
	margin: 1rem 1.5rem;
	padding: 0;
	border-left: dashed 2px darkgray;
`;

export const LeftLayer = styled.div`
	display: flex;
	/* border-right: 2px dashed darkgray; */
	min-width: 120px; /* 타이틀 영역 너비 조정 */
`;

export const TitleSection = styled.div`
	display: block;
	/* background-color: ${({ theme }) => theme.color.gray};
	border: 1px solid darkgray;
	border-radius: 1.5rem; */

	padding: 5px;
`;

export const SectionTitle = styled.div`
	display: flex;
	font-family: 'NotoSansKR-Bold';
	font-size: 1.8rem;
	/* background-color: ${({ theme }) => theme.color.gray};
	border: 1px solid darkgray;
	border-radius: 0.6rem; */
	padding: 0.8rem 1rem;
`;

export const RightLayer = styled.div`
	display: flex;
	text-align: left;
	width: 100%;
	padding: 0.3rem 0.7rem;

	background-color: white;
	border-top-right-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
`;

export const ContentSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 0.5rem;
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 3px;
		opacity: 0.7;
	}

	& .content {
		padding: 20px;
	}

	/* 내부 태그 관련 스타일 */
	& .tags {
		display: inline-flex;
		align-items: start;
		justify-content: start;
		vertical-align: middle;
		align-self: flex-start;
		color: rgb(120, 120, 120);
		border-radius: 8px;
		font-weight: bold;
		max-width: 12vh;
		font-size: 1.3rem;
		white-space: nowrap;
		padding: 4px 8px;
		margin: 0.2rem 0.4rem;

		&::before {
			content: '#';
		}

		&:hover {
			background-color: rgba(190, 190, 190, 0.8);
			transition: all 0.3s;
			color: white;
			cursor: pointer;
		}

		&.clicked {
			background-color: ${({ theme }) => theme.color.gold};
		}
	}
`;
