import styled, { css } from 'styled-components';

import torooc from '../../assets/image/torooc/torooc-logo.webp';
import torooc2 from '../../assets/image/torooc/torooc-logo@2x.webp';
import torooc3 from '../../assets/image/torooc/torooc-logo@3x.webp';

import apps from '../../assets/image/apps/apps.webp';
import apps2 from '../../assets/image/apps/apps@2x.webp';
import apps3 from '../../assets/image/apps/apps@3x.webp';

import alarm from '../../assets/image/alarm/alarm.webp';
import alarm2 from '../../assets/image/alarm/alarm@2x.webp';
import alarm3 from '../../assets/image/alarm/alarm@3x.webp';

import profile1 from '../../assets/image/profile/profile.webp';
import profile2 from '../../assets/image/profile/profile@2x.webp';
import profile3 from '../../assets/image/profile/profile@3x.webp';

import trashCan from '../../assets/image/trashcan/trashcan.webp';
import trashCan2 from '../../assets/image/trashcan/transhcan@2x.webp';
import trashCan3 from '../../assets/image/trashcan/trashcan@3x.webp';

import school from '../../assets/image/apps/Edu.webp';
import school2 from '../../assets/image/apps/Edu@2x.webp';
import school3 from '../../assets/image/apps/Edu@3x.webp';

import custom from '../../assets/image/apps/Custom.webp';
import custom2 from '../../assets/image/apps/Custom@2x.webp';
import custom3 from '../../assets/image/apps/Custom@3x.webp';

import play from '../../assets/image/apps/Play.webp';
import play2 from '../../assets/image/apps/Play@2x.webp';
import play3 from '../../assets/image/apps/Play@3x.webp';

import manage from '../../assets/image/apps/Manage.webp';
import manage2 from '../../assets/image/apps/Manage@2x.webp';
import manage3 from '../../assets/image/apps/Manage@3x.webp';

const torooc_list = [torooc, torooc2, torooc3];
const app_list = [apps, apps2, apps3];
const alarm_list = [alarm, alarm2, alarm3];
const profile_list = [profile1, profile2, profile3];
const trash_can_list = [trashCan, trashCan2, trashCan3];
const school_list = [school, school2, school3];
const custom_list = [custom, custom2, custom3];
const play_list = [play, play2, play3];
const manage_list = [manage, manage2, manage3];

export {
	torooc_list,
	app_list,
	alarm_list,
	profile_list,
	trash_can_list,
	school_list,
	custom_list,
	play_list,
	manage_list,
};

const setWidthResponsive = ({ mobile, tablet, desktop }) => css`
	${({ theme }) => theme.device.mobile} {
		width: ${mobile};
	}

	${({ theme }) => theme.device.tablet} {
		width: ${tablet};
	}

	${({ theme }) => theme.device.desktop} {
		width: ${desktop};
	}
`;

export const Container = styled.header`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 20vh;
	position: fixed;
	z-index: 999;
	height: 7.5rem;
	padding: 2.5rem;
	background-color: white;
	margin: 0 auto;
	${({ theme }) => theme.device.tablet} {
		padding: 2.5rem 0;
	}
`;

export const TopContentsSection = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	position: relative;
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
	${({ theme }) => theme.common.container};
`;

export const Left = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};

	.pageLogo {
		display: flex;
		justify-content: start;
		align-items: center;
		height: 100%;
		margin-left: 2rem;
		background-color: ${({ theme }) => theme.color.gray_ec};
		padding: 0.5rem 1.5rem;
		border-radius: 0.8rem;
		font-size: 1.6rem;
		column-gap: 1rem;

		img {
			width: 2rem;
			aspect-ratio: 1;
			object-fit: contain;
		}
		span {
			@media screen and (max-width: ${({ theme }) => theme.size.tablet}) {
				display: none;
			}
			white-space: nowrap;
			font-size: 16px;
			font-family: NotoSansKR-Bold;
		}
	}
`;

export const Center = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;
export const Right = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-end')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;

export const Logo = styled.img`
	${({ theme }) => theme.common.wh('auto', '100%')};
	&:hover {
		cursor: pointer;
	}
`;

export const Logo2 = styled.div`
	${({ theme }) => theme.common.wh('auto', '100%')};

	${({ theme }) => theme.device.mobile} {
		display: none;
	}
`;

export const ProfileWrap = styled.div`
	gap: 0 2rem;
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;

export const ModeToggleButton = styled.div`
	display: none;
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
	color: ${({ theme }) => theme.color.title};
	background-color: ${({ theme }) => theme.color.gray_f0};
	padding: 0.8rem 1.7rem;
	border-radius: 1.2rem;
	user-select: none;
	box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
	${({ theme }) => theme.device.tablet} {
		display: flex;
	}
	${setWidthResponsive({
		mobile: '10rem',
		tablet: '12rem',
		desktop: '18rem',
	})}
	& > span {
		${({ theme }) => theme.device.mobile} {
			font-size: 0;
		}

		${({ theme }) => theme.device.desktop} {
			font-size: 1.4rem;
		}
	}
	& > div {
		width: 6.4rem;
		background-color: ${({ theme, isBig }) =>
			!isBig ? theme.color.gray_e2 : theme.color.orange};
		color: white;
		padding: 0.6rem 1rem;
		border-radius: 0.8rem;
		text-align: center;
		cursor: pointer;
		&:hover {
			box-shadow: 0 0 0.5rem 0 ${({ theme }) => theme.color.orange};
			filter: brightness(0.95);
		}
	}
`;

export const ModeToggleButtonInProfile = styled.div`
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
	color: ${({ theme }) => theme.color.title};
	background-color: ${({ theme }) => theme.color.gray_f0};
	padding: 0.4rem 1rem;
	border-radius: 0.4rem;
	user-select: none;
	font-size: 1rem;
	box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;

	${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}

	& > span {
		font-size: 0.8rem;

		${({ theme }) => theme.device.desktop} {
			font-size: 1rem;
		}
	}
	& > div {
		width: 80%;
		margin-bottom: 0.5rem;
		${({ theme }) => theme.device.tablet} {
			width: 50%;
			margin-bottom: 0;
		}
		background-color: ${({ theme, isBig }) =>
			!isBig ? theme.color.gray_e2 : theme.color.orange};
		color: white;
		padding: 0.6rem 1rem;
		border-radius: 0.8rem;
		text-align: center;
		cursor: pointer;
		&:hover {
			box-shadow: 0 0 0.5rem 0 ${({ theme }) => theme.color.orange};
			filter: brightness(0.95);
		}
	}
`;

export const Profile = styled.button`
	aspect-ratio: 1 / 1;
	border: none;
	border-radius: 1.5rem;
	padding: 0;
	background-color: transparent;
	box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.16),
		inset 0 0 0.6rem 0 rgba(0, 0, 0, 0.16);
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('auto', '130%')};
`;

export const ProfileImage = css`
	object-fit: fill;
	cursor: pointer;
	${({ theme }) => theme.common.wh('auto', '150%')};
	&:hover {
		filter: drop-shadow(2px 4px 4px ${({ theme }) => theme.color.border});
	}
`;

export const ProfileModal = styled.div`
	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
	}
	cursor: default;
	position: absolute;
	display: ${({ open }) => (open ? 'flex' : 'none')};
	flex-direction: column;
	row-gap: 1rem;
	top: 150%;
	width: 20%;
	min-width: 10rem;
	max-width: 15rem;
	background-color: white;
	right: 0%;
	border: 1px solid ${({ theme }) => theme.color.regular};
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	z-index: 2;

	padding: 1rem 2rem;
`;

export const ProfileModalName = styled.div`
	& .bold {
		font-weight: bold;
	}
`;

export const LogoutButton = styled.div`
	padding: 0.5rem 0;
	margin: auto;
	cursor: pointer;
	border-radius: 2rem;
	width: 50%;
	max-width: 100px;
	border: 1px solid
		${({ theme }) => {
			return theme.color.border;
		}};
	font-size: 1rem;
	&:hover {
		border: 1px solid ${({ theme }) => theme.color.orange};
		color: ${({ theme }) => theme.color.orange};
	}
`;

export const CategorySection = styled.div`
	display: flex;
	position: absolute;
	width: 100%;
	height: 5vh;
	min-height: 55px;
	z-index: -99;
	top: 6rem;
	left: 0;

	/* &:hover .category {
		display: block;
	} */
`;

export const SearchSection = styled.div`
	display: flex;
	width: 100%;
	margin-top: 1rem;
`;
